export const getRealRenderHeight = (element, targetWidth = null) => {
  if (element.tagName !== 'IMG') {
    return element.clientHeight;
  }

  const sourceWidth = parseInt(element.getAttribute('width'), 10) || element.width;
  const sourceHeight = parseInt(element.getAttribute('height'), 10) || element.height;
  const ratio = sourceWidth / sourceHeight;
  const width = targetWidth || element.width || sourceWidth;

  return Math.floor(width / ratio);
};

import {
  CONTAINEDCLASS,
  CONTAINERCLASS,
  ONCLASS,
  getNodeZoomer,
  getRenderingQueue,
  lastOne,
  trigger
} from './utilities';

const zoomable = (element, options) => {
  element.classList.add(CONTAINERCLASS);
  element.classList.add(ONCLASS);

  const wrapper = document.createElement('div');

  wrapper.classList.add(CONTAINEDCLASS);
  Array.from(element.children).forEach((child) => {
    element.removeChild(child);
    wrapper.appendChild(child);
  });
  element.appendChild(wrapper);

  let nodeZoomer;

  const opts = Object.assign({}, {
    maxscale: 4,
    minscale: 1
  }, options);

  const renderingQueue = getRenderingQueue(lastOne);

  const handlers = {
    end () {
      nodeZoomer = undefined;
    },
    pan (event) {
      const [offsetx, offsety] = event.detail || [];

      if (!nodeZoomer) {
        nodeZoomer = getNodeZoomer(wrapper, opts.maxscale, opts.minscale);
      }

      {
        const zoomer = nodeZoomer;
        const cb = () => {
          zoomer.pan(offsetx, offsety);

          trigger(element, 'zoomable-after-pan', {
            detail: {zoomer}
          });
        };

        renderingQueue.push(cb);
      }
    },
    reset () {
      nodeZoomer = getNodeZoomer(wrapper, opts.maxscale, opts.minscale);
      nodeZoomer.reset();
      trigger(element, 'zoomable-after-zoom', {
        detail: {zoomer: nodeZoomer}
      });
      nodeZoomer = undefined;
    },
    zoom (event) {
      const detail = event.detail || {};
      const newscale = detail.newscale;
      let center = detail.center;

      if (!nodeZoomer) {
        nodeZoomer = getNodeZoomer(wrapper, opts.maxscale, opts.minscale);
      }

      if (!center) {
        const {height, width} = element.getBoundingClientRect();

        center = [width / 2, height / 2];
      }

      {
        const zoomer = nodeZoomer;
        const currentCenter = center;
        const cb = () => {
          zoomer.zoom(currentCenter[0], currentCenter[1], newscale);
          trigger(element, 'zoomable-after-zoom', {
            detail: {zoomer}
          });
        };

        renderingQueue.push(cb);
      }
    }
  };

  element.addEventListener('zoomable-reset', handlers.reset);
  element.addEventListener('zoomable-end', handlers.end);
  element.addEventListener('zoomable-zoom', handlers.zoom);
  element.addEventListener('zoomable-pan', handlers.pan);
};

export default zoomable;

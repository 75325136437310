/* eslint-disable complexity */
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {getGalleryArticlesByChannel} from '../../api/galleries';
import {Spinner} from '../Spinner';
import {ImageBox} from '../ImageBox';
import {MoreGalleriesList} from '../MoreGalleriesList';
import {VerticalMoreGalleriesList} from '../VerticalMoreGalleriesList';
import styles from './styles.scss';

const TOPIC_RESULT_COUNT = 4;
const CHANNEL_RESULT_COUNT = 4;

export const MoreGalleries = ({mode, isActive, onGetNextGallery = null, onGoToNextGallery = null}) => {
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [channelResults, setChannelResults] = useState(null);
  const [topicResults, setTopicResults] = useState(null);
  const currentChannel = getPageCriteria().channel || 'news';
  const {topics} = getPageMetadata();
  const topic = topics && topics[0];

  const fetchResults = async () => {
    setFetching(true);

    try {
      const [galleriesByChannel, galleriesByTopic] = await Promise.all([
        await getGalleryArticlesByChannel(currentChannel),
        topic ? await getGalleryArticlesByChannel(currentChannel, topic) : null
      ]);

      if (currentChannel !== 'news' && (!galleriesByChannel || galleriesByChannel.length < CHANNEL_RESULT_COUNT)) {
        const newsGalleries = await getGalleryArticlesByChannel('news');

        setChannelResults((galleriesByChannel || []).concat(newsGalleries || []));
      } else {
        setChannelResults(galleriesByChannel);
      }
      setTopicResults(galleriesByTopic);
    } catch (error) {
      logger.error('Error fetching gallery end slide results', error);
      setFetchError(error);
    }

    setLoading(false);
    setFetching(false);
  };

  useEffect(() => {
    if (!isActive || !loading || fetching || fetchError) {
      return;
    }

    fetchResults().catch();
  }, [isActive, fetching, loading, fetchError]);

  const showTopicGalleries = topicResults && topicResults.length >= TOPIC_RESULT_COUNT;
  const topicResultsToShow = showTopicGalleries && topicResults.slice(0, TOPIC_RESULT_COUNT);
  const articleIdsShownInTopics = topicResultsToShow && topicResultsToShow.map((item) => item.articleId);
  const filteredChannelResults = showTopicGalleries ? channelResults && channelResults.filter((item) => !articleIdsShownInTopics || !articleIdsShownInTopics.includes(item.articleId)) : channelResults;
  const channelResultCountWhenNotEnoughTopics = filteredChannelResults && filteredChannelResults.length >= TOPIC_RESULT_COUNT + CHANNEL_RESULT_COUNT ? TOPIC_RESULT_COUNT + CHANNEL_RESULT_COUNT : CHANNEL_RESULT_COUNT;
  const channelResultCountToShow = showTopicGalleries ? CHANNEL_RESULT_COUNT : channelResultCountWhenNotEnoughTopics;
  const channelResultsToShow = filteredChannelResults && filteredChannelResults.slice(0, channelResultCountToShow);
  const nextGallery = topicResultsToShow && topicResultsToShow[0] || channelResultsToShow && channelResultsToShow[0];

  useEffect(() => {
    if (onGetNextGallery && nextGallery) {
      onGetNextGallery(nextGallery);
    }
  }, [nextGallery]);

  if (loading) {
    return (
      <div className={classnames(styles.container, styles.loading)}>
        {loading && <Spinner />}
      </div>
    );
  }

  if (mode === 'horizontal') {
    const firstChannelResults = !showTopicGalleries && channelResultsToShow && channelResultsToShow.length >= CHANNEL_RESULT_COUNT && channelResultsToShow.slice(0, CHANNEL_RESULT_COUNT);
    const division = Math.floor(CHANNEL_RESULT_COUNT / 2);
    const secondChannelResults = showTopicGalleries ? channelResultsToShow && channelResultsToShow.slice(0, division) : channelResultsToShow && channelResultsToShow.slice(CHANNEL_RESULT_COUNT, CHANNEL_RESULT_COUNT + division);

    return (
      <div className={classnames(styles.container, styles.horizontal)}>
        {showTopicGalleries &&
          <MoreGalleriesList galleries={topicResultsToShow} title={`More ${topic} galleries`} />
        }
        {firstChannelResults && firstChannelResults.length > 0 &&
          <MoreGalleriesList galleries={firstChannelResults} title='Most popular galleries' />
        }
        <div className={classnames(styles.galleryNextWrapper, (!secondChannelResults || !secondChannelResults.length) && styles.noSecondResults)}>
          {secondChannelResults && secondChannelResults.length > 0 &&
            <MoreGalleriesList galleries={secondChannelResults} half={true} />
          }
          {onGoToNextGallery && nextGallery &&
            <div className={styles.viewNextWrapper} onClick={onGoToNextGallery}>
              <div className={styles.viewNextButton}>View next gallery</div>
              <div className={styles.viewNextPreview}>
                <div className={styles.viewNextThumb}>
                  <ImageBox image={nextGallery.images[0].hostUrl} ratio={0.7462} />
                </div>
                <div className={styles.viewNextHeadline}>
                  {nextGallery.headline}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  if (mode === 'smallHorizontal') {
    return (
      <div className={classnames(styles.container, styles.smallHorizontal)}>
        {showTopicGalleries && topicResultsToShow && topicResultsToShow.length >= 2 &&
          <MoreGalleriesList galleries={topicResultsToShow.slice(0, 2)} mobileMode={true} title={`More ${topic} galleries`} />
        }
        {!showTopicGalleries && channelResultsToShow && channelResultsToShow.length >= 2 &&
          <MoreGalleriesList galleries={channelResultsToShow.slice(0, 2)} mobileMode={true} title='Most popular galleries' />
        }
        {onGoToNextGallery && nextGallery &&
          <div className={styles.viewNextButton} onClick={onGoToNextGallery}>View next gallery</div>
        }
      </div>
    );
  }

  if (mode === 'vertical') {
    return (
      <div className={classnames(styles.container, styles.vertical)}>
        {showTopicGalleries &&
          <VerticalMoreGalleriesList galleries={topicResultsToShow} title={`More ${topic} galleries`} />
        }
        {channelResultsToShow && channelResultsToShow.length >= channelResultCountToShow &&
          <VerticalMoreGalleriesList galleries={channelResultsToShow} title='More galleries' />
        }
        {onGoToNextGallery && nextGallery &&
          <div className={styles.viewNextWrapper} onClick={onGoToNextGallery}>
            <div className={styles.viewNextButton}>View next gallery</div>
            <div className={styles.viewNextPreview}>
              <div className={styles.viewNextThumb}>
                <ImageBox image={nextGallery.images[0].hostUrl} ratio={0.7462} />
              </div>
              <div className={styles.viewNextHeadline}>
                {nextGallery.headline}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  return null;
};

import react from '@mol-fe/mol-fe-react';
import styles from './styles.scss';

const {React, PropTypes} = react;

const ImageBox = ({image, ratio}) => {
  const style = {};

  if (image && image.hostUrl) {
    style.backgroundImage = `url('${image.hostUrl}')`;
  }

  return (
    <div className={styles.boxMaster} style={{paddingBottom: `${ratio * 100}%`}}>
      <div className={styles.boxWrapper}>
        <div className={styles.image} style={style} />
      </div>
    </div>
  );
};

ImageBox.propTypes = {
  image: PropTypes.shape({
    hostUrl: PropTypes.string
  }),
  ratio: PropTypes.number
};

ImageBox.defaultProps = {
  image: null,
  ratio: 1
};

export default ImageBox;

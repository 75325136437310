import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';

let galleryMode;

export const setGalleryModeTracking = (mode) => {
  galleryMode = mode;
};

export const trackGalleryView = (galleryItem) => {
  if (!galleryItem) {
    return;
  }

  const {type, index, isNext, originalIndex, element} = galleryItem;
  let suffixes = '';

  if (galleryMode === 'vertical') {
    suffixes += ' vertical';
  }
  if (isNext) {
    suffixes += ' next';
  }

  pageEvents.publish(
    pageEvents.GALLERY_IMAGE_VIEWED,
    getPageMetadata({
      contentType: type,
      galleryId: `${type} ${index} enhanced${suffixes}`,
      galleryTitle: `${type} ${originalIndex} gallery view enhanced${suffixes}`,
      threadId: element && element.id
    })
  );
};

export const trackGalleryOpen = (galleryItem) => {
  if (!galleryItem) {
    return;
  }

  const {type, index, isNext, originalIndex, element} = galleryItem;
  let suffixes = '';

  if (galleryMode === 'vertical') {
    suffixes += ' vertical';
  }
  if (isNext) {
    suffixes += ' next';
  }

  pageEvents.publish(
    pageEvents.GALLERY_OPENED,
    getPageMetadata({
      contentType: type,
      galleryId: `${type} ${index} enhanced${suffixes}`,
      galleryTitle: `${type} ${originalIndex} gallery start enhanced${suffixes}`,
      threadId: element && element.id
    })
  );
};

export const trackGalleryClose = () => {
  pageEvents.publish(
    pageEvents.GALLERY_CLOSED,
    getPageMetadata({
      contentType: 'image',
      threadId: 'i-0'
    })
  );
};

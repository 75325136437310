/* eslint-disable id-length */
import React, {useCallback, useRef, useState} from 'react';
import QuickPinchZoom, {make3dTransformValue} from 'react-quick-pinch-zoom';
import styles from './styles.scss';

export const ZoomableImage = ({imageUrl, onClickImage, onZoomedIn, zoomableMode = false}) => {
  const zoomNormalRef = useRef(null);
  const zoomableRef = useRef(null);
  const [zoomModeOpen, setZoomModeOpen] = useState(false);

  const onUpdateNormal = useCallback(({x, y, scale}) => {
    const {current: img} = zoomNormalRef;

    if (onZoomedIn) {
      if (Math.abs(1 - scale) < 0.01) {
        onZoomedIn(false);
      } else {
        onZoomedIn(true);
      }
    }

    if (img) {
      const value = make3dTransformValue({
        scale,
        x,
        y
      });

      img.style.setProperty('transform', value);
    }
  }, []);

  const onDoubleTap = () => {
    if (zoomableRef.current && zoomableRef.current._zoomFactor > 1) {
      zoomableRef.current.scaleTo({
        scale: 1,
        x: 0,
        y: 0
      });
      setTimeout(() => {
        zoomableRef.current._onResize();
      }, 300);
      setTimeout(() => {
        zoomableRef.current._onResize();
      }, 500);
    }
  };

  if (!zoomableMode) {
    return (
      <QuickPinchZoom draggableUnZoomed={false} onDoubleTap={onDoubleTap} onUpdate={onUpdateNormal} ref={zoomableRef}>
        <img className={styles.image} onClick={onClickImage} ref={zoomNormalRef} src={imageUrl} />
      </QuickPinchZoom>
    );
  }

  if (zoomModeOpen) {
    return (
      <div className={styles.overlay} onClick={() => setZoomModeOpen(false)}>
        <QuickPinchZoom onDoubleTap={onDoubleTap} onUpdate={onUpdateNormal} ref={zoomableRef}>
          <img
            className={styles.image}
            onClick={(event) => {
              event.stopPropagation();
            }}
            ref={zoomNormalRef}
            src={imageUrl}
          />
        </QuickPinchZoom>
        <button className={styles.close} onClick={() => setZoomModeOpen(false)} />
      </div>
    );
  }

  return (
    <img className={styles.image} onClick={() => setZoomModeOpen(true)} src={imageUrl} />
  );
};

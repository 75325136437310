import React from 'react';
import styles from './styles.scss';

export const VerticalMoreGalleriesList = ({galleries, title}) =>
  <div className={styles.container}>
    <div className={styles.heading}>{title}</div>
    <div className={styles.items} data-track-module='gallery-recommendations^gallery'>
      {galleries.map(({images = [], headline, articleId, articleUrl}) =>
        <a className={styles.item} href={`${articleUrl}#gallery`} key={articleId}>
          <div className={styles.itemImage} style={{backgroundImage: `url(${images[0].hostUrl})`}} />
          <div className={styles.headline}>{headline}</div>
        </a>
      )}
    </div>
  </div>;

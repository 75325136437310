/**
 * Returns element offsetWidth.
 *
 * If the element is removed from the flow (style `{display: none}`)
 * then it makes the element visible to make the measurements and
 * hides the element after taking the measurements.
 */
export const getElementWidth = (element) => {
  const isElementHidden = element.style.display === 'none';

  if (isElementHidden) {
    element.style.display = 'block';
  }

  const elementWidth = element.offsetWidth;

  if (isElementHidden) {
    element.style.display = 'none';
  }

  return elementWidth;
};

export default {
  getElementWidth
};

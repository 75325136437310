const throttle = function (func, delay = 100) {
  let timeoutId;
  let lastArgs;

  return (...args) => new Promise((resolve) => {
    lastArgs = args;

    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        timeoutId = null;
        resolve(func(lastArgs));
      }, delay);
    }
  });
};

export default throttle;

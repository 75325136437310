import react from '@mol-fe/mol-fe-react';
import Slide from '../components/Slide';
import articlesStore from './articlesStore';

const {render: reactRender} = react;

const renderToElement = (component, props = {}) => {
  const wrapper = document.createElement('div');

  reactRender(Slide, props, wrapper);

  return wrapper.children[0];
};

const getPageMetadata = () => window.DM && window.DM.getPageMetadata && window.DM.getPageMetadata() || {};

class ShareSlideManager {
  lastResult = null;

  constructor () {
    articlesStore.addArticle(getPageMetadata().articleId);
    this.init = this.init.bind(this);
  }

  getElement () {
    if (!this.element) {
      this._setRecommendations([{}, {}, {}, {}]);
    }

    return this.element;
  }

  _setRecommendations (recommendations) {
    const {articleTitle} = getPageMetadata();

    const element = renderToElement(Slide, {
      recommendations,
      title: articleTitle
    });

    element.init = this.init;

    if (this.element) {
      this.element.replaceWith(element);
    }

    this.element = element;
  }

  async fetch () {
    if (!this.lastResult) {
      const {channel} = getPageMetadata();

      this.lastResult = await fetch(`/api/most-watched-videos/rta/ig${channel}/json`).then((res) => res.json());
    }

    return this.lastResult;
  }

  async init ({bkgImage}) {
    if (bkgImage && this.element.parentNode && this.element.parentNode.children.length === 1) {
      this.element.parentNode.insertBefore(bkgImage, this.element);
    }

    const result = await this.fetch();
    const seenArticlesIds = articlesStore.getArticles();
    const unseenArticles = [];
    const seenArticles = [];

    result.items.forEach((item) => {
      if (seenArticlesIds.includes(item.articleId)) {
        seenArticles.push(item);
      } else {
        unseenArticles.push(item);
      }
    });

    this._setRecommendations([...unseenArticles, ...seenArticles].slice(0, 4));
  }
}

export default ShareSlideManager;

const polyFill = function (event, params) {
  const newParams = params || {
    bubbles: false,
    cancelable: false
  };

  newParams.bubbles = newParams.bubbles || false;
  newParams.cancelable = newParams.cancelable || false;

  const evt = document.createEvent('CustomEvent');

  evt.initCustomEvent(event, newParams.bubbles, newParams.cancelable, newParams.detail);

  return evt;
};

polyFill.prototype = window.Event.prototype;

const createCustomEvent = (event, params) => {
  // to avoid getting someone else's polyfill
  if (typeof window.CustomEvent === 'function' && window.CustomEvent.prototype.toString() === '[object CustomEvent]') {
    return new window.CustomEvent(event, params);
  } else {
    // IE all version do not support CustomEvent
    return polyFill(event, params);
  }
};

export default createCustomEvent;

import {getWidestSideAdWidth} from './adverts';

// This number is provided by PM's is width of the main
// content plus the skyscraper ads.
export const screenBreakPoint = () => document.body.clientWidth >= 1485;

/**
 * Previous, next navigation can only be displayed if there are no
 * side ads that the navigation would overlay.
 *
 * This script calculate the available width on the page
 * minus the content width and visible ads width.
 */
export const calculateAvailableWidth = (getWidest = getWidestSideAdWidth) => {
  const windowWidth = window.innerWidth;
  const contentWidth = document.getElementById('content').offsetWidth;
  const widestSideAdWidth = getWidest();

  // Some ads have 20 "margin"
  // (ads are absolutely positioned) from the content.
  const adMarginWidth = 20;

  // Available width on the sides of the page.
  return windowWidth - contentWidth - 2 * (adMarginWidth + widestSideAdWidth);
};

export default {
  calculateAvailableWidth,
  screenBreakPoint
};

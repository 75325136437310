import {later} from '@mol-fe/mol-fe-async';

const cloneNewVideo = (image) => {
  const wrapper = document.createElement('div');

  wrapper.className = image.className;
  wrapper.id = image.id + '-clone';

  later('videoplayer-ready', () => {
    const video = image.children[0];
    const videoClone = video && window.DM.setupMolVideoJsPlayers.clone(
      video,
      {
        plugins: {
          tracking: {
            trackingType: 'image_gallery'
          }
        }
      }
    );

    if (!videoClone) {
      return;
    }

    videoClone.id = video.id + '-clone';
    wrapper.appendChild(videoClone);
    window.DM.setupMolVideoJsPlayers(wrapper);
  });

  return wrapper;
};

const cloneImage = (image) => {
  // In terms of gallery both videos and images are treated the same - slides with different content type
  const isVideo = Boolean(image.getAttribute('data-video-opts'));
  const isNewVideo = image.classList.contains('vjs-video-container');
  let content;
  let imageCaption;
  let contentType;

  if (isVideo) {
    contentType = 'videoimage';
    content = image.cloneNode();
    content.setAttribute('id', content.getAttribute('id') + '-clone');
    Array.from(content.children).forEach((child) => {
      if (child.classList.contains('js-video')) {
        child.innerHTML = '';
        child.classList.remove('playing');
      } else {
        content.removeChild(child);
      }
    });

    content.video({
      objId: '#' + content.getAttribute('id'),
      trackingType: 'image_gallery'
    });
  } else if (isNewVideo) {
    content = cloneNewVideo(image);
    contentType = 'videoimage';
  } else {
    const captionNode = image.parentNode && image.parentNode.querySelector('.imageCaption');

    contentType = 'image';
    imageCaption = captionNode && captionNode.innerHTML || image.getAttribute('alt');
    content = image.cloneNode();
    content.style.position = '';
    content.style.top = '';
    content.style.height = '';
  }

  content.classList.add('slide');
  content.setAttribute('data-content-type', contentType);
  content.setAttribute('data-caption', imageCaption);

  return content;
};

export default cloneImage;

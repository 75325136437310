import createCustomEvent from '../createCustomEvent';

/*
zoom and pan utility plugins
*/
const PREFIXES = ['Moz', 'Webkit', 'O', 'ms'];
const SCALEX = 0;
const SKEWX = 1;
const SKEWY = 2;
const SCALEY = 3;
const TRANSLATEX = 4;
const TRANSLATEY = 5;
const CONTAINERCLASS = 'pinchzoom-container';
const ONCLASS = 'pinchzoom-on';
const CONTAINEDCLASS = 'pinchzoom-item';

const polyfillRequestAnimationFrame = () => {
  const vendors = ['webkit', 'moz'];
  let lastTime = 0;

  for (let idx = 0; idx < vendors.length && !window.requestAnimationFrame; ++idx) {
    window.requestAnimationFrame = window[vendors[idx] + 'RequestAnimationFrame'];
    window.cancelAnimationFrame =
      window[vendors[idx] + 'CancelAnimationFrame'] || window[vendors[idx] + 'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (func) => {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(
        () => {
          func(currTime + timeToCall);
        },
        timeToCall
      );

      lastTime = currTime + timeToCall;

      return id;
    };
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = (id) => {
      clearTimeout(id);
    };
  }
};

const lastOne = (queue) => {
  if (queue.length) {
    return [queue.pop()];
  }

  return [];
};

const getRenderingQueue = (inputPolicy) => {
  const passThrough = (input) => input;
  const policy = inputPolicy || passThrough;
  let queue = [];
  let isRunning = false;

  const render = () => {
    queue = policy(queue);
    isRunning = false;

    let func = queue.shift();

    while (func) {
      func();

      func = queue.shift();
    }
  };

  return {
    empty () {
      queue = [];
    },
    push (func) {
      queue.push(func);
      if (!isRunning) {
        isRunning = true;
        window.requestAnimationFrame(render);
      }
    }
  };
};

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getWithPrefixes = (obj, prop) => {
  let value = obj[prop];

  if (value) {
    return value;
  }

  for (let idx = 0; idx < PREFIXES.length; idx++) {
    value = obj[PREFIXES[idx] + capitalize(prop)];
    if (value) {
      return value;
    }
  }

  return null;
};

const setWithPrefixes = (obj, prop, value) => {
  obj[prop] = value;

  for (let idx = 0; idx < PREFIXES.length; idx++) {
    obj[PREFIXES[idx] + capitalize(prop)] = value;
  }
};

const getTransformMatrix = (transform) => {
  const regex = /matrix\(([^)]*)\)/;
  const match = transform && transform.match(regex);

  if (transform && match) {
    const valuesStr = match[1].split(',');
    const values = [];

    for (let idx = 0; idx < 6; idx++) {
      values.push(parseFloat(valuesStr[idx]));
    }

    return values;
  } else {
    return [1, 0, 0, 1, 0, 0];
  }
};

const setTransformMatrix = (arr) => 'matrix(' + arr.join(', ') + ')';

const newMatrix = () => [1, 0, 0, 1, 0, 0];

const getMatrix = (node) => {
  const computedStyle = window.getComputedStyle(node);
  const CSStransform = getWithPrefixes(computedStyle, 'transform');

  return getTransformMatrix(CSStransform);
};

const setMatrix = (node, matrix) => {
  const value = setTransformMatrix(matrix);

  setWithPrefixes(node.style, 'transform', value);
};

// eslint-disable-next-line id-match
const origin2translate = (origin, scale) => origin * (1 - scale);

const matrixMul = (m1, m2) => {
  const m3 = new Array(6);

  m3[0] = m1[0] * m2[0] + m1[2] * m2[1] + m1[4] * 0;
  m3[2] = m1[0] * m2[2] + m1[2] * m2[3] + m1[4] * 0;
  m3[4] = m1[0] * m2[4] + m1[2] * m2[5] + Number(m1[4]);

  m3[1] = m1[1] * m2[0] + m1[3] * m2[1] + m1[5] * 0;
  m3[3] = m1[1] * m2[2] + m1[3] * m2[3] + m1[5] * 0;
  m3[5] = m1[1] * m2[4] + m1[3] * m2[5] + Number(m1[5]);

  return m3;
};

const getCoord = (point, matrix) => {
  const coord = new Array(2);

  coord[0] = matrix[SCALEX] * point[0] + matrix[SKEWX] * point[1] + matrix[TRANSLATEX];
  coord[1] = matrix[SCALEY] * point[1] + matrix[SKEWY] * point[0] + matrix[TRANSLATEY];

  return coord;
};

const getNodeZoomer = (node, inputMaxScale, inputMinScale) => {
  const originalHeight = node.clientHeight;
  const originalWidth = node.clientWidth;
  const originalMatrix = getMatrix(node);
  const maxscale = (inputMaxScale || 4) / originalMatrix[SCALEX];
  const minscale = (inputMinScale || 1) / originalMatrix[SCALEX];
  let resultMatrix = matrixMul(originalMatrix, newMatrix());

  const isOver = (direction, minPoint, maxPoint) => {
    switch (direction.toUpperCase()) {
    case 'N': return minPoint[1] <= 0 && maxPoint[1] <= originalHeight;
    case 'S': return minPoint[1] >= 0 && maxPoint[1] >= originalHeight;
    case 'W': return minPoint[0] <= 0 && maxPoint[0] <= originalWidth;
    case 'E': return minPoint[0] >= 0 && maxPoint[0] >= originalWidth;
    }

    return null;
  };

  const limitBoundaries = (matrix) => {
    const minPoint = getCoord([0, 0], matrix);
    const maxPoint = getCoord([originalWidth, originalHeight], matrix);

    if (isOver('W', minPoint, maxPoint)) {
      matrix[TRANSLATEX] = originalWidth - (maxPoint[0] - minPoint[0]);
    }
    if (isOver('E', minPoint, maxPoint)) {
      matrix[TRANSLATEX] = 0;
    }
    if (isOver('N', minPoint, maxPoint)) {
      matrix[TRANSLATEY] = originalHeight - (maxPoint[1] - minPoint[1]);
    }
    if (isOver('S', minPoint, maxPoint)) {
      matrix[TRANSLATEY] = 0;
    }

    return matrix;
  };

  return {
    cannotPan (direction) {
      const minPoint = getCoord([0, 0], resultMatrix);
      const maxPoint = getCoord([originalWidth, originalHeight], resultMatrix);
      const dirs = direction.split('');
      const over = [];

      for (let idx = 0; idx < dirs.length; idx++) {
        if (isOver(dirs[idx], minPoint, maxPoint)) {
          over.push(dirs[idx]);
        }
      }

      return over.join('');
    },
    getZoom () {
      return resultMatrix[SCALEX];
    },
    pan (distanceX, distanceY) {
      const matrix = newMatrix();

      matrix[TRANSLATEX] = distanceX;
      matrix[TRANSLATEY] = distanceY;
      resultMatrix = limitBoundaries(matrixMul(matrix, originalMatrix));
      setMatrix(node, resultMatrix);
    },
    reset () {
      resultMatrix = newMatrix();
      setMatrix(node, resultMatrix);
    },
    zoom (centerx, centery, inputNewScale) {
      let newscale = inputNewScale;

      if (newscale <= minscale) {
        newscale = minscale;
      } else if (newscale >= maxscale) {
        newscale = maxscale;
      }

      const matrix = newMatrix();

      matrix[SCALEX] = newscale;
      matrix[SCALEY] = newscale;
      matrix[TRANSLATEX] = origin2translate(centerx, newscale);
      matrix[TRANSLATEY] = origin2translate(centery, newscale);

      resultMatrix = limitBoundaries(matrixMul(originalMatrix, matrix));
      setMatrix(node, resultMatrix);
    }
  };
};

const trigger = (element, eventName, params) => {
  element.dispatchEvent(createCustomEvent(eventName, params));
};

polyfillRequestAnimationFrame();

export {
  lastOne,
  getRenderingQueue,
  getNodeZoomer,
  trigger,
  CONTAINERCLASS,
  ONCLASS,
  CONTAINEDCLASS
};

/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {addBeforeOpenHandler} from './ArticleGallery';
import ArticlePage from './ArticlePage';

const loaded = (() => {
  const {enhancedGallery} = getPageCriteria();

  if (enhancedGallery || window.location.search && window.location.search.match(/debugEnhancedGallery=true/)) {
    return;
  }

  window.ArticlePage = window.ArticlePage || ArticlePage;
})();

export {loaded, addBeforeOpenHandler};

const getOpenGraphDescription = function () {
  const openGraphDescription = document.head.querySelector('meta[property="og:description"]');

  return openGraphDescription && openGraphDescription.getAttribute('content');
};

const getOpenGraphImage = function () {
  const openGraphImage = document.head.querySelector('meta[property="og:image"]');

  return openGraphImage && openGraphImage.getAttribute('content');
};

const getOpenGraphTitle = function () {
  const openGraphTitle = document.head.querySelector('meta[property="og:title"]');

  return openGraphTitle && openGraphTitle.getAttribute('content');
};

export {
  getOpenGraphDescription,
  getOpenGraphImage,
  getOpenGraphTitle
};

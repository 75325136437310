import {later} from '@mol-fe/mol-fe-async';
import {createConvoElement} from '@mol-fe/mol-fe-openweb-core';

const ID = 'liveBlogComment';
const LIMIT = 2;
const MODULE_NAME = 'mol-fe-live-blog';

const renderLiveBlogComment = () => {
  const container = document.getElementById(ID);

  if (container) {
    const convoElem = createConvoElement({
      messagesCount: LIMIT,
      module: MODULE_NAME
    });

    container.appendChild(convoElem);
    container.removeAttribute('data-hide');
  }
};

export const initLiveBlogOW = () => {
  window.addEventListener('LIVEBLOG_OW_READY', () => {
    later('OPENWEB_LOADED', renderLiveBlogComment);
  });
};

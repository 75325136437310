import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import caretDown from './assets/caret-down.svg';
// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';

export const initialiseReadMore = (element) => {
  const {renderPlatform} = getPageMetadata();
  const {articleType} = getPageCriteria();

  // This is a mobile only feature, no permabox
  if (renderPlatform !== 'mobile' || articleType === 'permabox') {
    return;
  }

  const readMoreContainer = document.createElement('div');
  const readMoreBtn = document.createElement('button');

  readMoreContainer.className = 'mol-fe-factbox-read-more-container';
  readMoreBtn.className = 'mol-fe-factbox-read-more-btn';
  readMoreBtn.innerHTML = `<span class="mol-fe-factbox-read-more-btn-text">Read more</span>
                              <img class="mol-fe-factbox-read-more-btn-caret" src="${caretDown}" alt="caret down">`;
  readMoreBtn.addEventListener('click', () => {
    readMoreContainer.style.visibility = 'hidden';
    element.style.maxHeight = 'none';

    try {
      pageEvents.publish(pageEvents.FACTBOX_READ_MORE_CLICK);
    } catch (error) {
      logger.error('Error tracking factbox read more click', error);
    }
  });

  readMoreContainer.appendChild(readMoreBtn);
  element.appendChild(readMoreContainer);
};

import React from 'react';
import classnames from 'classnames';
import {ImageBox} from '../ImageBox';
import styles from './styles.scss';

export const MoreGalleriesList = ({galleries, title = null, half = false, mobileMode = false}) =>
  <div className={classnames(styles.container, half && styles.half, mobileMode && styles.mobileMode)}>
    {title && <div className={styles.heading}>{title}</div>}
    <div className={styles.items} data-track-module='gallery-recommendations^gallery'>
      {galleries.map(({images = [], imageCount, headline, articleId, articleUrl}) =>
        <a className={styles.item} href={`${articleUrl}#gallery`} key={articleId}>
          <div>
            <ImageBox image={images[0].hostUrl} ratio={0.60177} />
          </div>
          <div className={styles.imageStrip}>
            <div className={styles.itemImage}>
              <ImageBox image={images[1].hostUrl} ratio={0.73333} />
            </div>
            <div className={styles.itemImage}>
              <ImageBox image={images[2].hostUrl} ratio={0.73333} />
            </div>
            <div className={styles.itemImageCount}>{imageCount ? `+${imageCount}` : '...'}</div>
          </div>
          <div className={styles.headline}>{headline}</div>
        </a>
      )}
    </div>
  </div>;

import {logger} from '@mol-fe/mol-fe-client-logger';
import Rotator from '../Rotator';

const initAutoInitRotators = () => {
  Array.from(document.querySelectorAll('[data-dm-rotator-auto-init]')).forEach((el) => {
    const configuration = {
      activeClass: el.getAttribute('data-dm-rotator-active-class'),
      pageCount: parseInt(el.getAttribute('data-dm-rotator-page-count'), 10),
      pageSize: parseInt(el.getAttribute('data-dm-rotator-page-size'), 10)
    };

    try {
      configuration.rotate = JSON.parse(el.getAttribute('data-dm-rotator-rotate'));
    } catch (error) {
      logger.error(
        `Failed to parse value as JSON in initAutoInitRotators (attribute: data-dm-rotator-rotate, value: ${el.getAttribute('data-dm-rotator-rotate')})`
      );
    }

    // eslint-disable-next-line no-new
    new Rotator(el.getAttribute('id'), configuration);
  });
};

export default initAutoInitRotators;

import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import {cloneNewVideo} from '../../api/videoplayer';
import styles from './styles.scss';

export const VideoSlide = ({galleryItem, isActive, mobileMode}) => {
  const videoElRef = useRef(null);

  useEffect(() => {
    if (!videoElRef.current) {
      return;
    }

    const videoEl = videoElRef.current && videoElRef.current.querySelector('video[data-opts]');
    const player = videoEl && videoEl.player;

    if (isActive && !videoEl) {
      const newVideoElement = cloneNewVideo(galleryItem.element);

      videoElRef.current.appendChild(newVideoElement);
    }

    if (!isActive && player && !player.paused()) {
      player.pause();
    }
  }, [isActive]);

  return (
    <div className={classnames(styles.videoWrapper, mobileMode && styles.mobileMode)}>
      <div ref={videoElRef} />
    </div>
  );
};

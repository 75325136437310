/* eslint-disable complexity */
import React from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {getGalleryItems} from './getGalleryItems';
import {pauseAll as pauseAllVideos} from './api/videoplayer';
import {HorizontalGallery} from './components/HorizontalGallery';
import {VerticalGallery} from './components/VerticalGallery';
import {notifyAdsGalleryClose, notifyAdsGalleryOpen} from './api/ads';
import {trackGalleryClose, trackGalleryOpen, setGalleryModeTracking} from './api/tracking';
import {runBeforeOpenHandlers} from './beforeOpen';

let galleryElement = null;

export const closeGallery = () => {
  if (!galleryElement) {
    return;
  }

  unmountComponentAtNode(galleryElement);
  galleryElement.remove();
  galleryElement = null;
  document.body.classList.remove('enhanced-gallery-open');

  trackGalleryClose();
  notifyAdsGalleryClose();
};

// Reorders gallery so it starts from the selected item, but wraps around
const reorderGalleryItemsFromIndex = (galleryItems, startIndex) =>
  galleryItems
    .slice(startIndex)
    .concat(galleryItems.slice(0, startIndex))
    .map((item, index) => ({
      ...item,
      index
    }));

export const openGallery = ({allowVideos = true, startIndex = null} = {}) => {
  if (galleryElement || window.isInlinePaywallMounted) {
    return;
  }

  pauseAllVideos();
  runBeforeOpenHandlers();
  notifyAdsGalleryOpen();

  galleryElement = document.createElement('div');
  document.body.appendChild(galleryElement);

  let galleryItems = getGalleryItems({allowVideos});

  if (startIndex) {
    galleryItems = reorderGalleryItemsFromIndex(galleryItems, startIndex);
  }

  const {renderPlatform} = getPageMetadata();
  const {enhancedGalleryMode} = getPageCriteria();
  let mode = renderPlatform === 'mobile' ? 'vertical' : 'horizontal';
  const horizontalFromQueryString = window.location.search && window.location.search.match(/debugEnhancedGalleryMobileMode=horizontal/);
  const verticalFromQueryString = window.location.search && window.location.search.match(/debugEnhancedGalleryMobileMode=vertical/);

  if ((horizontalFromQueryString || enhancedGalleryMode === 'horizontal') && !verticalFromQueryString) {
    mode = 'horizontal';
  }

  setGalleryModeTracking(mode);

  if (mode === 'horizontal') {
    render(
      <HorizontalGallery
        allowVideos={allowVideos}
        galleryItems={galleryItems}
        isMobilePage={renderPlatform === 'mobile'}
        onClose={closeGallery}
      />,
      galleryElement
    );
  } else if (mode === 'vertical') {
    render(
      <VerticalGallery
        allowVideos={allowVideos}
        galleryItems={galleryItems}
        onClose={closeGallery}
      />,
      galleryElement
    );
  }

  document.body.classList.add('enhanced-gallery-open');

  trackGalleryOpen(galleryItems[0]);
};

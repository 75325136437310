import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';

const tracking = {};
const galleryTitle = (content, index, suffix) => [content, index, suffix].join(' ');
const galleryId = (content, index) => [content, index].join(' ');
let adStats = {};

const trackGalleryView = (slidePos, content, imageId, imagePos) => {
  pageEvents.publish(
    pageEvents.GALLERY_IMAGE_VIEWED,
    getPageMetadata({
      contentType: content,
      galleryId: galleryId(content, slidePos),
      galleryTitle: galleryTitle(content, imagePos, 'gallery view'),
      threadId: imageId
    })
  );
};

const trackGalleryOpen = (slidePos, content, imageId, imagePos) => {
  tracking.track = trackGalleryView;
  pageEvents.publish(
    pageEvents.GALLERY_OPENED,
    getPageMetadata({
      contentType: content,
      galleryId: galleryId(content, slidePos),
      galleryTitle: galleryTitle(content, imagePos, 'gallery start'),
      threadId: imageId
    })
  );
};

const trackGalleryAdvert = (index, advert, locked) => {
  const key = 'advert_' + index;

  if (!adStats[key]) {
    adStats[key] = {
      bounces: 0,
      views: 1
    };

    return;
  }

  if (locked) {
    adStats[key].bounces++;
  } else {
    adStats[key].views++;
  }

  pageEvents.publish(
    pageEvents.GALLERY_ADVERT_VIEWED,
    getPageMetadata({
      contentType: 'advert',
      threadId: index
    })
  );
};

const trackGalleryEnd = () => {
  pageEvents.publish(
    pageEvents.GALLERY_ADVERT_STATS,
    {
      stats: JSON.stringify(adStats),
      type: 'gallery-advert'
    }
  );

  adStats = {};

  pageEvents.publish(
    pageEvents.GALLERY_CLOSED,
    getPageMetadata({
      contentType: 'image',
      threadId: 'i-0'
    })
  );
};

tracking.init = () => {
  tracking.track = trackGalleryOpen;
};
tracking.track = trackGalleryOpen;
tracking.trackGalleryAdvert = trackGalleryAdvert;
tracking.trackGalleryEnd = trackGalleryEnd;

export default tracking;

// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {init, show} from './init';

const loaded = (async () => {
  try {
    await later('DOM_READY');
    await init();

    return true;
  } catch (error) {
    logger.error('Error initialising floating share bar', error);

    return false;
  }
})();

export {loaded, init, show};

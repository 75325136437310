/* eslint-disable complexity, id-length */
import {translate, circle} from './translation';
import {addListeners, removeListeners, removeTouchListeners} from './listeners';
import animate from './animate';
import createLinkHandler from './createLinkHandler';

const noop = () => {};
const offloadFn = (fn) => {
  setTimeout(fn || noop, 0);
};

const Swipe = function (container, options = {}) {
  let touchEnabled = true;

  // check browser capabilities
  const browser = {
    addEventListener: Boolean(window.addEventListener),
    pointer: window.navigator.msPointerEnabled,
    touch: 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch,
    transitions: ((temp) => {
      const props = ['transitionProperty', 'WebkitTransition', 'MozTransition', 'OTransition', 'msTransition'];
      let prop;

      for (prop in props) {
        if (temp.style[props[prop]] !== undefined) {
          return true;
        }
      }

      return false;
    })(document.createElement('swipe'))
  };

  // quit if no root element
  if (!container) {
    return null;
  }
  const element = container.children[0];
  const speed = options.speed || 300;
  let index = parseInt(options.startSlide, 10) || 0;
  let slides;
  let slidePos;
  let width;
  let length;
  let next = noop;

  // setup auto slideshow
  let delay = options.auto || 0;
  let interval;

  // setup initial vars
  let delta = {};
  let start = {};
  let isScrolling;
  let resizeFired;

  options.continuous = typeof options.continuous === 'undefined' ? true : options.continuous;

  if (browser.pointer) {
    container.style.msTouchAction = 'none';
  }

  const move = (idx, dist, newSpeed) => {
    translate(idx, dist, newSpeed, slides);
    slidePos[idx] = dist;
  };

  const setup = () => {
    // cache slides
    slides = element.children;
    length = slides.length;

    // set continuous to false if only one slide
    if (slides.length < 2) {
      options.continuous = false;
    }

    // special case if two slides
    if (browser.transitions && options.continuous && slides.length < 3) {
      element.appendChild(slides[0].cloneNode(true));
      element.appendChild(element.children[1].cloneNode(true));
      slides = element.children;
    }

    // create an array to store current positions of each slide
    slidePos = new Array(slides.length);

    // determine width of each slide
    width = container.getBoundingClientRect().width || container.offsetWidth;

    element.style.width = slides.length * width + 'px';

    // stack elements
    let pos = slides.length;

    while (pos--) {
      const slideAtPos = slides[pos];

      slideAtPos.style.width = width + 'px';
      slideAtPos.setAttribute('data-index', pos);

      let widthAtPos = 0;

      if (index > pos) {
        widthAtPos = -width;
      } else if (index < pos) {
        widthAtPos = width;
      }

      if (browser.transitions) {
        slideAtPos.style.left = pos * -width + 'px';
        move(pos, widthAtPos, 0);
      }
    }

    // reposition elements before and after index
    if (options.continuous && browser.transitions) {
      move(circle(index - 1, slides.length), -width, 0);
      move(circle(index + 1, slides.length), width, 0);
    }

    if (!browser.transitions) {
      element.style.left = index * -width + 'px';
    }

    container.style.visibility = 'visible';
  };

  const begin = () => {
    interval = setTimeout(next, delay);
  };

  const slide = (toIndex, slideSpeed) => {
    let to = toIndex;

    // do nothing if already on requested slide
    if (index === to) {
      return;
    }

    if (browser.transitions) {
      // 1: backward, -1: forward
      let direction = Math.abs(index - to) / (index - to);

      // get the actual position of the slide
      if (options.continuous) {
        const naturalDirection = direction;

        direction = -slidePos[circle(to, slides.length)] / width;

        // if going forward but to < index, use to = slides.length + to
        // if going backward but to > index, use to = -slides.length + to
        if (direction !== naturalDirection) {
          to = -direction * slides.length + to;
        }
      }

      let diff = Math.abs(index - to) - 1;

      // move all the slides between index and to in the right direction
      while (diff--) {
        move(
          circle((to > index ? to : index) - diff - 1, slides.length),
          width * direction,
          0
        );
      }

      to = circle(to, slides.length);

      move(index, width * direction, slideSpeed || speed);
      move(to, 0, slideSpeed || speed);

      // we need to get the next in place
      if (options.continuous) {
        move(
          circle(to - direction, slides.length),
          -(width * direction),
          0
        );
      }
    } else {
      // no fallback for a circular continuous if the browser does not accept transitions
      to = circle(to, slides.length);
      animate(
        index * -width,
        to * -width,
        slideSpeed || speed,
        element,
        slides,
        index,
        options,
        () => {
          if (delay) {
            begin();
          }
        }
      );
    }

    index = to;
    offloadFn(options.callback && options.callback(index, slides[index]));
  };

  const prev = () => {
    if (options.continuous) {
      slide(index - 1);
    } else if (index) {
      slide(index - 1);
    }
  };

  next = () => {
    if (options.continuous) {
      slide(index + 1);
    } else if (index < slides.length - 1) {
      slide(index + 1);
    }
  };

  const stop = () => {
    delay = 0;
    clearTimeout(interval);
  };

  // setup event capturing
  const events = {
    end (event) {
      if (!touchEnabled) {
        return;
      }

      // measure duration
      const duration = Date.now() - start.time;

      // determine if slide attempt triggers next/prev slide
      const isValidSlide =
        Number(duration) < 250 &&
        Math.abs(delta.x) > 20 ||
        Math.abs(delta.x) > width / 2;

      // determine if slide attempt is past start and end
      let isPastBounds =
        !index && delta.x > 0 ||
        index === slides.length - 1 && delta.x < 0;

      if (options.continuous) {
        isPastBounds = false;
      }

      // determine direction of swipe (true:right, false:left)
      const direction = delta.x < 0;

      // if not scrolling vertically
      if (!isScrolling) {
        if (isValidSlide && !isPastBounds) {
          event.stopPropagation();

          if (direction) {
            if (options.continuous) {
              move(circle(index - 1, slides.length), -width, 0);
              move(circle(index + 2, slides.length), width, 0);
            } else {
              move(index - 1, -width, 0);
            }

            move(index, slidePos[index] - width, speed);
            move(circle(index + 1, slides.length), slidePos[circle(index + 1, slides.length)] - width, speed);
            index = circle(index + 1, slides.length);
          } else {
            if (options.continuous) {
              move(circle(index + 1, slides.length), width, 0);
              move(circle(index - 2, slides.length), -width, 0);
            } else {
              move(index + 1, width, 0);
            }

            move(index, slidePos[index] + width, speed);
            move(circle(index - 1, slides.length), slidePos[circle(index - 1, slides.length)] + width, speed);
            index = circle(index - 1, slides.length);
          }

          if (options.callback) {
            options.callback(index, slides[index]);
          }
        } else if (options.continuous) {
          move(circle(index - 1, slides.length), -width, speed);
          move(index, 0, speed);
          move(circle(index + 1, slides.length), width, speed);
        } else {
          move(index - 1, -width, speed);
          move(index, 0, speed);
          move(index + 1, width, speed);
        }
      }

      // kill touchmove and touchend event listeners until touchstart called again
      removeTouchListeners(browser, element, events);
    },
    handleEvent (event) {
      const linkHandler = createLinkHandler(element);

      switch (event.type) {
      case 'touchstart':
        this.start(event);
        break;
      case 'touchmove':
        this.move(event);
        break;
      case 'touchend':
        offloadFn(this.end(event));
        break;

      // IE10 pointer events
      case 'MSPointerDown':
        this.start(event);
        break;
      case 'MSPointerMove':
        this.move(event);

        // handles links inside the slider
        if (delta.x) {
          linkHandler.toggleOnPreventClick();
        }
        break;
      case 'MSPointerUp':
        offloadFn(this.end(event));
        break;
      case 'webkitTransitionEnd':
      case 'msTransitionEnd':
      case 'oTransitionEnd':
      case 'otransitionend':
      case 'transitionend':
        offloadFn(this.transitionEnd(event));
        linkHandler.toggleOffPreventClick();
        break;
      case 'resize':
        resizeFired = true;
        offloadFn(setup.call());
        break;
      case 'orientationchange':
        // IOS is not firing resize loading videos
        resizeFired = false;
        setTimeout(() => {
          if (!resizeFired) {
            setup.call();
          }
        }, 1000);
        break;
      }

      if (options.stopPropagation) {
        event.stopPropagation();
      }
    },
    move (event) {
      if (!touchEnabled) {
        return;
      }

      // ensure swiping with one touch and not pinching
      if (!browser.pointer && event.touches.length > 1 || event.scale && event.scale !== 1) {
        return;
      }

      if (options.disableScroll) {
        event.preventDefault();
      }

      if (browser.pointer) {
        delta = {
          x: (event.pageX || event.clientX) - start.x,
          y: (event.pageY || event.clientY) - start.y
        };
      } else {
        const touches = event.touches[0];

        delta = {
          x: touches.pageX - start.x,
          y: touches.pageY - start.y
        };
      }

      // determine if scrolling test has run - one time test
      if (typeof isScrolling === 'undefined') {
        isScrolling = Boolean(Math.abs(delta.x) < Math.abs(delta.y));
      }

      // if user is not trying to scroll vertically
      if (!isScrolling) {
        // prevent native scrolling
        event.preventDefault();

        // stop slideshow
        stop();

        // increase resistance if first or last slide
        if (options.continuous) {
          // we don't add resistance at the end
          translate(circle(index - 1, slides.length), delta.x + slidePos[circle(index - 1, slides.length)], 0, slides);
          translate(index, delta.x + slidePos[index], 0, slides);
          translate(circle(index + 1, slides.length), delta.x + slidePos[circle(index + 1, slides.length)], 0, slides);
        } else {
          delta.x /=
            !index && delta.x > 0 || index === slides.length - 1 && delta.x < 0 ?
              Math.abs(delta.x) / width + 1 :
              1;

          // translate 1:1
          translate(index - 1, delta.x + slidePos[index - 1], 0, slides);
          translate(index, delta.x + slidePos[index], 0, slides);
          translate(index + 1, delta.x + slidePos[index + 1], 0, slides);
        }
      }
    },
    start (event) {
      if (!touchEnabled) {
        return;
      }

      // used for testing first move event
      isScrolling = undefined;

      // reset delta and end measurements
      delta = {};

      if (browser.pointer) {
        start = {
          time: Date.now(),
          x: event.pageX || event.clientX,
          y: event.pageY || event.clientY
        };

        element.addEventListener('MSPointerMove', this, false);
        element.addEventListener('MSPointerUp', this, false);
      } else {
        const touches = event.touches[0];

        start = {
          time: Date.now(),
          x: touches.pageX,
          y: touches.pageY
        };

        element.addEventListener('touchmove', this, false);
        element.addEventListener('touchend', this, false);
      }
    },
    transitionEnd (event) {
      if (parseInt(event.target.getAttribute('data-index'), 10) === index) {
        if (delay) {
          begin();
        }

        if (options.transitionEnd) {
          options.transitionEnd.call(event, index, slides[index]);
        }
      }
    }
  };

  // trigger setup
  setup();

  // start auto slideshow if applicable
  if (delay) {
    begin();
  }

  // add event listeners
  addListeners(browser, element, events, setup);

  // expose the Swipe API
  return {
    disableTouch () {
      touchEnabled = false;
    },
    enableTouch () {
      touchEnabled = true;
    },
    getNumSlides () {
      // return total number of slides
      return length;
    },
    getPos () {
      // return current index position
      return index;
    },
    kill () {
      // cancel slideshow
      stop();

      // reset element
      element.style.width = 'auto';
      element.style.left = 0;

      // reset slides
      let pos = slides.length;

      while (pos--) {
        const slideAtPos = slides[pos];

        slideAtPos.style.width = '100%';
        slideAtPos.style.left = 0;

        if (browser.transitions) {
          translate(pos, 0, 0, slides);
        }
      }

      // removed event listeners
      removeListeners(browser, element, events);
    },
    next () {
      // cancel slideshow
      stop();
      next();
    },
    prev () {
      // cancel slideshow
      stop();
      prev();
    },
    setup () {
      setup();
    },
    slide (to, newSpeed) {
      // cancel slideshow
      stop();
      slide(to, newSpeed);
    }
  };
};

export default Swipe;

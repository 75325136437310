/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
// eslint-disable-next-line import/no-unassigned-import
import './index.scss';
import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {stickProductButton} from './stickyBar';

const loaded = (async () => {
  try {
    await later('DOM_READY');

    const products = Array.from(
      document.querySelectorAll('.mol-products-module')
    ).filter((el) => {
      // do not pass if cta is hidden or dataset | href does not exist or contain hashtag (#)
      const product = el.querySelector('.mol-product');
      const cta = product.querySelector('.mol-product-cta');
      const url = product.dataset.stickyProductUrl || cta.href;

      if (product.classList.contains('hide-cta') || url.match(/^\#|\#$/g)) {
        return false;
      } else {
        return true;
      }
    });

    const override = window.stickyProductBarOverride;

    // proceed if one product on a page & if bar exists in experiments
    if (products.length === 1 || override) {
      stickProductButton(products && products[0], override);
    }

    return products;
  } catch (error) {
    logger.error(
      'Error during mol-fe-products-sticky-bar initialisation: ',
      error
    );

    return null;
  }
})();

export {loaded};

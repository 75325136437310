/* eslint-disable import/max-dependencies */
import React, {useState} from 'react';
import classnames from 'classnames';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getArticleDom} from '../../api/articleDom';
import {addArticleIdToFilter, removeArticleIdFromFilter} from '../../api/galleries';
import {setBaseShareArticleUrl, setSocialDescription, setSocialTitle} from '../../api/social';
import {trackGalleryOpen} from '../../api/tracking';
import {getSlotEnabled, isAdFreeEntitled} from '../../api/ads';
import {getGalleryItems} from '../../getGalleryItems';
import {VerticalSlide} from '../VerticalSlide';
import {AdSlot} from '../AdSlot';
import {MoreGalleries} from '../MoreGalleries';
import styles from './styles.scss';

const MPU_ENABLED = true;
const MPU_STEP = 2;

const insertMPUEveryNth = (elements, step) => {
  if (!MPU_ENABLED || !getSlotEnabled('mobile_gallery') || isAdFreeEntitled()) {
    return elements;
  }

  const elementsWithAds = elements.slice();

  for (let mpuCount = 0; mpuCount < elements.length / step - 1; mpuCount++) {
    const posToInsert = mpuCount + (mpuCount + 1) * step;

    if (elementsWithAds.length < posToInsert) {
      return elementsWithAds;
    }

    const positionId = `mobile_gallery_${mpuCount + 1}`;
    const mpuElement =
      <div className={styles.mpuWrapper} key={positionId}>
        <AdSlot position='mobile_gallery' slotId={positionId} slotType='mpu' variant={mpuCount + 1} />
      </div>;

    elementsWithAds.splice(posToInsert, 0, mpuElement);
  }

  return elementsWithAds;
};

const originalDescriptionMeta = document.querySelector('meta[name="description"]');

export const VerticalGallery = ({galleryItems, onClose, allowVideos = true}) => {
  const [currentGallery, setCurrentGallery] = useState({
    articleId: getPageMetadata().articleId,
    articleTitle: getPageMetadata().articleTitle,
    closeUrl: null,
    description: originalDescriptionMeta && originalDescriptionMeta.content,
    galleryItems
  });
  const [nextGallery, setNextGallery] = useState(null);
  const [previousGalleries, setPreviousGalleries] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const handleGetNextGallery = async ({articleUrl, articleId, headline}) => {
    try {
      const nextArticleDom = await getArticleDom(articleUrl);
      const newGalleryItems = getGalleryItems({
        allowVideos,
        articleId,
        dom: nextArticleDom
      });
      const descriptionMeta = nextArticleDom.querySelector('meta[name="description"]');

      if (newGalleryItems && newGalleryItems.length) {
        setNextGallery({
          articleId,
          articleTitle: headline,
          closeUrl: articleUrl,
          description: descriptionMeta && descriptionMeta.content,
          galleryItems: newGalleryItems
        });
      }
    } catch (error) {
      logger.error('Error prefetching next gallery', error);
    }
  };

  const setSocialAttributes = (gallery) => {
    setBaseShareArticleUrl(gallery.closeUrl);
    setSocialDescription(gallery.description);
    setSocialTitle(gallery.articleTitle);
  };

  const loadNextGallery = () => {
    if (!nextGallery || !nextGallery.galleryItems) {
      return;
    }

    addArticleIdToFilter(nextGallery.articleId);
    setSocialAttributes(nextGallery);
    setPreviousGalleries([...previousGalleries, currentGallery]);
    setCurrentGallery(nextGallery);
    setNextGallery(null);

    trackGalleryOpen(nextGallery.galleryItems[0]);
  };

  const loadPreviousGallery = () => {
    if (!previousGalleries.length) {
      return;
    }

    const previousGallery = previousGalleries[previousGalleries.length - 1];

    removeArticleIdFromFilter(currentGallery.articleId);
    setSocialAttributes(previousGallery);
    setNextGallery(currentGallery);
    setCurrentGallery(previousGallery);
    setPreviousGalleries(previousGalleries.slice(0, previousGalleries.length - 1));

    trackGalleryOpen(previousGallery.galleryItems[0]);
  };

  const handleClose = () => {
    if (!currentGallery.closeUrl) {
      onClose();

      return;
    }

    setDisabled(true);

    window.addEventListener('popstate', () => {
      setDisabled(false);
    });

    window.location.href = `${currentGallery.closeUrl}?ico=nextgallery-close`;
  };

  const gallerySlides = currentGallery.galleryItems.map((galleryItem) =>
    <VerticalSlide
      galleryItem={galleryItem}
      key={galleryItem.index}
      totalCount={currentGallery.galleryItems.length}
    />
  );
  const renderItems = insertMPUEveryNth(gallerySlides, MPU_STEP);
  const adFree = isAdFreeEntitled();

  return (
    <div className={classnames(styles.overlay, disabled && styles.disabled)} data-swipe-article='false'>
      <div className={styles.controls}>
        <button className={styles.close} onClick={handleClose}>Close</button>
        {previousGalleries.length > 0 &&
          <button className={styles.previousGalleryButton} onClick={loadPreviousGallery}>Previous gallery</button>
        }
        <button className={styles.nextGalleryButton} disabled={!nextGallery} onClick={nextGallery && loadNextGallery}>Next gallery</button>
      </div>
      <div className={styles.scroller} key={currentGallery.articleId}>
        <div className={styles.headingWrapper}>
          <div className={styles.heading}>
            {currentGallery.articleTitle}
          </div>
          {previousGalleries.length > 0 &&
            <a className={styles.readMoreLink} onClick={handleClose}>Read more...</a>
          }
        </div>
        {renderItems}
        <MoreGalleries isActive={true} mode='vertical' onGetNextGallery={handleGetNextGallery} onGoToNextGallery={loadNextGallery} />
      </div>
      {!adFree &&
        <div className={styles.bannerWrapper}>
          <AdSlot position='sticky_banner_gallery_bottom' slotId='sticky_banner_gallery_bottom' slotType='staticMobileBanner' />
        </div>
      }
    </div>
  );
};

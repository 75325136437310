/* eslint-disable complexity, import/max-dependencies */
import React, {useState, useEffect} from 'react';
import withSizes from 'react-sizes';
import classnames from 'classnames';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {getArticleDom} from '../../api/articleDom';
import {addArticleIdToFilter, removeArticleIdFromFilter} from '../../api/galleries';
import {setBaseShareArticleUrl, setSocialDescription, setSocialTitle} from '../../api/social';
import {trackGalleryOpen, trackGalleryView} from '../../api/tracking';
import {getSlotEnabled, isAdFreeEntitled} from '../../api/ads';
import {getGalleryItems} from '../../getGalleryItems';
import {Controls} from '../Controls';
import {HorizontalSlider} from '../HorizontalSlider';
import {AdSlot} from '../AdSlot';
import styles from './styles.scss';

const INTERSTITIALS_ENABLED = true;
const INTERSTITIALS_STEP = 2;

export const injectInterstitials = (galleryItems) => {
  if (!INTERSTITIALS_ENABLED || !getSlotEnabled('mobile_gallery') || isAdFreeEntitled()) {
    return galleryItems;
  }

  const itemsWithAds = galleryItems.slice();

  for (let mpuCount = 0; mpuCount < galleryItems.length / INTERSTITIALS_STEP - 1; mpuCount++) {
    const posToInsert = mpuCount + (mpuCount + 1) * INTERSTITIALS_STEP;

    if (itemsWithAds.length < posToInsert) {
      return itemsWithAds;
    }

    const positionId = `mobile_gallery_${mpuCount + 1}`;

    itemsWithAds.splice(posToInsert, 0, {
      positionId,
      type: 'interstitial',
      variant: mpuCount + 1
    });
  }

  let index = 0;

  for (const item of itemsWithAds) {
    item.index = index++;

    if (item.type === 'interstitial') {
      item.originalIndex = item.index;
    }
  }

  return itemsWithAds;
};

const originalDescriptionMeta = document.querySelector('meta[name="description"]');

export const HorizontalGalleryBase = ({galleryItems, onClose, startIndex, billboardSize, skySize, mobileSize = false, allowVideos = true, isMobilePage = false, shortForBanners = false}) => {
  const mobileMode = mobileSize || isMobilePage;
  const [currentIndex, setCurrentIndex] = useState(startIndex || 0);
  const [showControls, setShowControls] = useState(true);
  const [currentGallery, setCurrentGallery] = useState({
    articleId: getPageMetadata().articleId,
    articleTitle: getPageMetadata().articleTitle,
    closeUrl: null,
    description: originalDescriptionMeta && originalDescriptionMeta.content,
    galleryItems
  });
  const [nextGallery, setNextGallery] = useState(null);
  const [previousGalleries, setPreviousGalleries] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const currentGalleryWithInterstitials = mobileMode ? injectInterstitials(currentGallery.galleryItems) : currentGallery.galleryItems;

  useEffect(() => {
    setCurrentIndex(startIndex || 0);
  }, [mobileMode]);

  const setSocialAttributes = (gallery) => {
    setBaseShareArticleUrl(gallery.closeUrl);
    setSocialDescription(gallery.description);
    setSocialTitle(gallery.articleTitle);
  };

  const loadNextGallery = () => {
    if (!nextGallery || !nextGallery.galleryItems) {
      return;
    }

    addArticleIdToFilter(nextGallery.articleId);
    setSocialAttributes(nextGallery);
    setPreviousGalleries([...previousGalleries, currentGallery]);
    setCurrentGallery(nextGallery);
    setNextGallery(null);
    setCurrentIndex(0);

    trackGalleryOpen(nextGallery.galleryItems[0]);
  };

  const loadPreviousGallery = () => {
    if (!previousGalleries.length) {
      return;
    }

    const previousGallery = previousGalleries[previousGalleries.length - 1];

    removeArticleIdFromFilter(currentGallery.articleId);
    setSocialAttributes(previousGallery);
    setNextGallery(currentGallery);
    setCurrentGallery(previousGallery);
    setPreviousGalleries(previousGalleries.slice(0, previousGalleries.length - 1));

    const previousGalleryLastIndex = mobileMode ? injectInterstitials(previousGallery.galleryItems).length - 1 : previousGallery.galleryItems.length - 1;

    setCurrentIndex(previousGalleryLastIndex);

    trackGalleryOpen(previousGallery.galleryItems[previousGalleryLastIndex]);
  };

  const handleNext = () => {
    if (disabled) {
      return;
    }

    setCurrentIndex((currValue) => {
      if (currValue < currentGalleryWithInterstitials.length) {
        if (currValue + 1 >= currentGalleryWithInterstitials.length) {
          trackGalleryView({
            index: currValue + 1,
            isNext: previousGalleries.length > 0,
            originalIndex: currValue + 1,
            type: 'shareslide'
          });
        } else {
          trackGalleryView(currentGalleryWithInterstitials[currValue + 1]);
        }

        return currValue + 1;
      } else if (nextGallery) {
        loadNextGallery();
      }

      return currValue;
    });
  };

  const handlePrevious = () => {
    if (disabled) {
      return;
    }

    setCurrentIndex((currValue) => {
      if (currValue > 0) {
        trackGalleryView(currentGalleryWithInterstitials[currValue - 1]);

        return currValue - 1;
      } else if (previousGalleries.length > 0) {
        loadPreviousGallery();
      }

      return currValue;
    });
  };

  const toggleControls = () => {
    setShowControls((currValue) => !currValue);
  };

  const handleGetNextGallery = async ({articleUrl, articleId, headline}) => {
    try {
      const nextArticleDom = await getArticleDom(articleUrl);
      const newGalleryItems = getGalleryItems({
        allowVideos,
        articleId,
        dom: nextArticleDom
      });
      const descriptionMeta = nextArticleDom.querySelector('meta[name="description"]');

      if (newGalleryItems && newGalleryItems.length) {
        setNextGallery({
          articleId,
          articleTitle: headline,
          closeUrl: articleUrl,
          description: descriptionMeta && descriptionMeta.content,
          galleryItems: newGalleryItems
        });
      }
    } catch (error) {
      logger.error('Error prefetching next gallery', error);
    }
  };

  const handleClose = () => {
    if (!currentGallery.closeUrl) {
      onClose();

      return;
    }

    setDisabled(true);

    window.addEventListener('popstate', () => {
      setDisabled(false);
    });

    window.location.href = `${currentGallery.closeUrl}?ico=nextgallery-close`;
  };

  const currentItem = currentGalleryWithInterstitials && currentGalleryWithInterstitials[currentIndex];
  const adFree = isAdFreeEntitled();

  return (
    <div className={classnames(styles.overlay, adFree && styles.adFree, disabled && styles.disabled, mobileMode && styles.mobileMode, billboardSize && styles[`${billboardSize}Billboard`], skySize && styles[`${skySize}Sky`])}>
      {mobileMode && !billboardSize && !shortForBanners && !adFree &&
        <div className={styles.bannerWrapper}>
          <AdSlot position='sticky_banner_gallery_top' slotId='sticky_banner_gallery_top' slotType='staticMobileBanner' />
        </div>
      }
      <div className={styles.galleryWrapper} data-swipe-article={false}>
        <HorizontalSlider
          articleTitle={currentGallery.articleTitle}
          currentIndex={currentIndex}
          galleryItems={currentGalleryWithInterstitials}
          key={currentGallery.articleId}
          mobileMode={mobileMode}
          onGetNextGallery={handleGetNextGallery}
          onGoToNextGallery={nextGallery ? loadNextGallery : null}
          onGoToPreviousGallery={previousGalleries.length > 0 ? loadPreviousGallery : null}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onToggleControls={toggleControls}
        />
        <Controls
          articleTitle={currentGallery.articleTitle}
          caption={currentItem && currentItem.type === 'image' && currentItem.element.alt}
          currentIndex={currentIndex}
          disabled={disabled}
          hasNextGallery={Boolean(nextGallery)}
          hasPreviousGallery={previousGalleries.length > 0}
          itemCount={currentGalleryWithInterstitials.length + 1}
          mobileMode={mobileMode}
          onClose={handleClose}
          onNext={handleNext}
          onPrevious={handlePrevious}
          showControls={showControls}
        />
      </div>
      {skySize && !adFree &&
        <AdSlot position='sky_left_gallery' size={skySize} slotId='sky_left_gallery' slotType='skyScraperLeft' />
      }
      {skySize && !adFree &&
        <AdSlot position='sky_right_gallery' size={skySize} slotId='sky_right_gallery' slotType='skyScraperRight' />
      }
      {billboardSize && !adFree &&
        <AdSlot position='gallery_billboard' size={billboardSize} slotId='gallery_billboard' slotType='billboard' />
      }
      {mobileMode && !billboardSize && !shortForBanners && !adFree &&
        <div className={styles.bannerWrapper}>
          <AdSlot position='sticky_banner_gallery_bottom' slotId='sticky_banner_gallery_bottom' slotType='staticMobileBanner' />
        </div>
      }
    </div>
  );
};

const mapSizesToProps = ({width, height}) => {
  let billboardSize = null;
  let skySize = null;
  let mobileSize = false;
  let shortForBanners = false;

  if (width >= 1264 && height >= 600) {
    skySize = 'small';
  }
  if (width >= 1600 && height >= 600) {
    skySize = 'big';
  }
  if (width >= 768 && height >= 700) {
    billboardSize = 'small';
  }
  if (width >= 1024 && height >= 950) {
    billboardSize = 'big';
  }
  if (width < 768) {
    mobileSize = true;
  }
  if (height < 500) {
    shortForBanners = true;
  }
  if (width >= 1500 && height < 700) {
    billboardSize = null;
  }

  return {
    billboardSize,
    mobileSize,
    shortForBanners,
    skySize
  };
};

export const HorizontalGallery = withSizes(mapSizesToProps)(HorizontalGalleryBase);

import react from '@mol-fe/mol-fe-react';
import Recommendations from '../Recommendations';
import SocialButtons from '../SocialButtons';
import styles from './styles.scss';

const {React, PropTypes} = react;

const Slide = (props) => {
  const {title, recommendations} = props;

  return (
    <div className={`${styles.container} slide`} data-share={true}>
      <div className={styles.body}>
        <div className={styles.heading}>
          <div className={styles.headingInfo}>Share this story</div>
          <div className={styles.headingTitle}>{title || ''}</div>
        </div>
        <div className={styles.socialButtons}>
          <SocialButtons />
        </div>
        <div className={styles.recommendations}>
          <Recommendations items={recommendations} />
        </div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  recommendations: PropTypes.array,
  title: PropTypes.string
};

Slide.defaultProps = {
  recommendations: [],
  title: ''
};

export default Slide;

import styles from '../previousNextNavigation.scss';

/**
 * Creates an interactive HTML element and appends to document.body.
 */
export const createNavigationElement = (prevOrNext, onClick) => {
  const wrapper = document.createElement('div');
  const button = document.createElement('div');

  wrapper.classList.add(styles.prevNextWrapper);
  wrapper.classList.add(styles[prevOrNext]);
  button.classList.add(styles.prevNextButton);

  button.addEventListener('click', () => {
    onClick();
  });

  wrapper.appendChild(button);
  document.body.appendChild(wrapper);

  return button;
};

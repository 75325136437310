import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {openGallery} from './mount';
import {getRealRenderHeight} from './helpers';
import styles from './styles.scss';

const wrapImage = (element) => {
  const wrapper = document.createElement('div');

  wrapper.className = 'image-wrap';
  element.parentNode.insertBefore(wrapper, element);
  element.parentNode.removeChild(element);
  wrapper.appendChild(element);
};

const MIN_SMALL_HEIGHT = 445;
const MIN_SMALL_WIDTH = 445;

export const attachGalleryItemHandlers = (galleryItem, {allowVideos = true, itemCount = null} = {}) => {
  if (galleryItem.type !== 'image') {
    return;
  }

  const {element} = galleryItem;
  const {renderPlatform} = getPageMetadata();
  const isFirstMobileImage = galleryItem.index === 0 && renderPlatform === 'mobile';

  if (element.dataset.galleryHandlerAttached) {
    return;
  }

  if (!isFirstMobileImage && !element.closest('.image-wrap')) {
    wrapImage(element);
  }

  const container = isFirstMobileImage ? element.parentNode : element.closest('.image-wrap');

  container.style.cursor = 'pointer';
  container.addEventListener('click', () => {
    try {
      window.DM.molFeLoadingSpinner.showSpinner();
    } catch (error) {
      logger.error('Error showing mol-fe-loading-spinner', error);
    }

    // Using setTimeout to ensure the spinner is shown before gallery is opened (as openGallery function is blockng the UI thread)
    setTimeout(() => {
      openGallery({
        allowVideos,
        startIndex: galleryItem.index
      });

      try {
        window.DM.molFeLoadingSpinner.hideSpinner();
      } catch (error) {
        logger.error('Error hiding mol-fe-loading-spinner', error);
      }
    }, 0);
  });

  const openGalleryButton = document.createElement('button');

  openGalleryButton.classList.add(styles.openGalleryButton);
  openGalleryButton.innerHTML = `
    <div class="${styles.imageCount}">${itemCount ? `<span class="${styles.plusSign}">+</span>${itemCount}` : ''}</div>
    <div class="${styles.viewGalleryText}">View gallery</div>
  `;

  if (getRealRenderHeight(element) < MIN_SMALL_HEIGHT || element.clientWidth < MIN_SMALL_WIDTH) {
    openGalleryButton.classList.add(styles.small);
  }

  container.appendChild(openGalleryButton);

  element.dataset.galleryHandlerAttached = true;
};

import axios from 'axios';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';

const MWV_BY_ENVIRONMENT = {
  development: 'https://www.dailymailint.co.uk/api/most-watched-videos',
  integration: 'https://www.dailymailint.co.uk/api/most-watched-videos',
  production: 'https://www.dailymail.co.uk/api/most-watched-videos'
};

const IMAGE_MIN_COUNT = 20;
const MIN_RESULT_COUNT = 6;

let articleIdsFilter = [String(getPageMetadata().articleId)];

export const addArticleIdToFilter = (articleId) => {
  articleIdsFilter.push(String(articleId));
};

export const removeArticleIdFromFilter = (articleId) => {
  articleIdsFilter = articleIdsFilter.filter((id) => id !== String(articleId));
};

export const isFilteredArticleId = (articleId) => articleIdsFilter.includes(String(articleId));

export const getGalleryArticlesByChannel = async (channel, topic = null) => {
  const {environment} = getPageMetadata();
  const topicFilter = topic ? `/topic/${encodeURIComponent(topic)}` : '';
  const url = `${MWV_BY_ENVIRONMENT[environment]}/rta/ig/${channel}${topicFilter}/json`;

  try {
    const response = await axios.get(url);
    const withoutImageMin = response.data && response.data.items
      .filter(
        (item) =>
          item.images && item.images.length >= 3 &&
          !isFilteredArticleId(item.articleId)
      );

    if (withoutImageMin && withoutImageMin.length > 0) {
      const withImageMin = withoutImageMin.filter((item) => item.imageCount >= IMAGE_MIN_COUNT);

      if (withImageMin && withImageMin.length >= MIN_RESULT_COUNT) {
        return withImageMin;
      }

      return withoutImageMin;
    }

    return null;
  } catch (error) {
    // Failures treated as empty responses
    return null;
  }
};

import {getZoom} from '@mol-fe/mol-fe-mobileutils';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';

const indexOf = (image, images) => {
  for (let idx = 0; idx < images.length; idx++) {
    if (image.src === images[idx].src) {
      return idx;
    }
  }

  return null;
};

const supported = () => {
  if (navigator.userAgent.match(/Opera Mini/)) {
    return false;
  }

  return true;
};

const trackZoom = () => {
  const PageCriteria = getPageCriteria();

  if (Boolean(PageCriteria.isMobile && getZoom() > 1)) {
    document.body.classList.add('zoomed');
  } else {
    document.body.classList.remove('zoomed');
  }
};

const wrapElement = (element) => {
  const wrapper = document.createElement('div');

  wrapper.className = 'image-wrap';
  element.parentNode.insertBefore(wrapper, element);
  element.parentNode.removeChild(element);
  wrapper.appendChild(element);
};

export {
  indexOf,
  supported,
  trackZoom,
  wrapElement
};

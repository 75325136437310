const getAdsLib = () => {
  if (!window.adverts) {
    throw new Error('Missing required adverts library');
  }

  return window.adverts;
};

let widestSideAdWidth = 0;

/**
 * We are assuming that any ad that has a proportion
 * height to width GTE 4 is a sky ad on a side of the page.
 */
export const isSidebarAd = (adDefinition) => adDefinition.height / adDefinition.width >= 3;

export const updateWidestSideAdWidth = (adDefinition) => {
  if (!isSidebarAd(adDefinition)) {
    return;
  }

  if (adDefinition.width > widestSideAdWidth) {
    widestSideAdWidth = adDefinition.width;
  }
};

export const getWidestSideAdWidth = () => widestSideAdWidth;

export const onAdRender = (callback) => {
  getAdsLib().on('rendered', (event) => {
    callback({
      element: document.getElementById(event.slot.molAd.parentId),
      height: event.size[1],
      positionName: event.slot.molAd.pos,
      width: event.size[0]
    });
  });
};

export const getAds = () => {
  const renderedAds = getAdsLib().getRendered();

  return renderedAds
    .map((adDefinition) => {
      const adElement = document.getElementById(adDefinition.parentId);

      return {
        element: adElement,
        height: adElement.offsetHeight,
        positionName: adDefinition.pos,
        width: adElement.offsetWidth
      };
    });
};

/**
 * Returns true if the adverts library have been set and false otherwises.
 */
export const hasAdverts = () => {
  try {
    return Boolean(getAdsLib());
  } catch (error) {
    return false;
  }
};

/**
 * Detect ads that take the entire sidebar.
 */
export const isSkinAdPresent = () => {
  const adIds = getAds()
    .map(({element}) => {
      if (element && element.id) {
        return element.id.toLowerCase();
      }

      return '';
    });

  return adIds.indexOf('billboard-oop') !== -1;
};

export default {
  getAds,
  getWidestSideAdWidth,
  hasAdverts,
  isSidebarAd,
  isSkinAdPresent,
  onAdRender
};

const translate = (idx, dist, newSpeed, slides) => {
  const slideToTranslate = slides[idx];
  const style = slideToTranslate && slideToTranslate.style;

  if (!style) {
    return;
  }

  const transitionDuration = newSpeed + 'ms';
  const transformNonWebkit = 'translateX(' + dist + 'px)';
  const transformWebkit = 'translate(' + dist + 'px,0) translateZ(0)';

  style.webkitTransitionDuration = transitionDuration;
  style.MozTransitionDuration = transitionDuration;
  style.msTransitionDuration = transitionDuration;
  style.OTransitionDuration = transitionDuration;
  style.transitionDuration = transitionDuration;
  style.webkitTransform = transformWebkit;
  style.msTransform = transformNonWebkit;
  style.MozTransform = transformNonWebkit;
  style.OTransform = transformNonWebkit;
};

const circle = (idx, numItems) => (numItems + idx % numItems) % numItems;

export {
  translate,
  circle
};

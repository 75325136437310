/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {wrapElement} from './helpers';
import openGallery from './openGallery';

const disableEnlarge = (image) => {
  const parent = image.closest('a');

  if (parent) {
    parent.classList.remove('lightboxPopupLink');
    if (parent.parentNode.tagName === 'FONT') {
      parent.parentNode.style.fontSize = '';
    }
  }
};

const processImage = (imageElement, counter, images) => {
  const PageCriteria = getPageCriteria();
  const {renderPlatform} = getPageMetadata();
  const isFirstMobileImage = counter === 1 && renderPlatform === 'mobile';

  if (!isFirstMobileImage && !imageElement.closest('.image-wrap')) {
    wrapElement(imageElement);
  }

  disableEnlarge(imageElement);

  const container = isFirstMobileImage ? imageElement.parentNode : imageElement.closest('.image-wrap');

  imageElement.insertAdjacentHTML(
    'afterend',
    '<div class="overlay-icon mobile-gallery"><div class="mobile-gallery-icon"></div><span>+' + images.length + '</span></div>'
  );

  const galleryIconWidth = imageElement.nextElementSibling.getBoundingClientRect().width || 0;

  if (!PageCriteria.isMobile) {
    const sharePictures = document.getElementById('share-pictures');

    if (sharePictures && imageElement.id) {
      const overlay = sharePictures.cloneNode(true);
      const {height, width} = imageElement.getBoundingClientRect();

      overlay.attr('id', 'share-pictures-' + counter);
      imageElement.insertAdjacentElement('afterend', overlay);

      if (height < 370) {
        const links = document.querySelector('#share-pictures-' + counter + ' .share-pictures-links');

        links.classList.add('image-share-horizontal');
        links.style.right = `${galleryIconWidth + 20}px`;

        if (width < 390) {
          links.classList.add('image-share-hide');
        }
      }

      window.DMS.ImageShare.initOnImage(overlay, container);
    }
  }

  container.addEventListener('click', () => {
    openGallery(imageElement, images);
  });
  container.style.cursor = 'pointer';
};

export default processImage;

import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';

export const hideWhileCommentsVisible = (floatingBar) => {
  const {openwebEnabled} = getPageCriteria();
  const {renderPlatform} = getPageMetadata();
  const commentsContainer = document.getElementById('reader-comments') || document.getElementById('reader-comments-in-overlay');

  if (!openwebEnabled || renderPlatform !== 'desktop' || !commentsContainer) {
    return;
  }

  const observer = new IntersectionObserver((entries) => {
    const entry = entries && entries[0];

    if (entry && entry.isIntersecting) {
      floatingBar.classList.add('hide-on-comments-visible');
    } else {
      floatingBar.classList.remove('hide-on-comments-visible');
    }
  });

  observer.observe(commentsContainer);
};

import URL from 'url-parse';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';

const POSITION_NAME = 'image_gallery-last';
let baseShareArticleUrl = null;
const originalDescriptionMeta = document.querySelector('meta[name="description"]');
let socialDescription = originalDescriptionMeta && originalDescriptionMeta.content;
let socialTitle = getPageMetadata().articleTitle;

const getArticleUrl = (addHash = true) => {
  const {origin, pathname} = new URL(baseShareArticleUrl || location.href);

  return `${origin}${pathname}${addHash ? '#gallery' : ''}`;
};

export const setBaseShareArticleUrl = (newUrl) => {
  baseShareArticleUrl = newUrl;
};

export const setSocialDescription = (description) => {
  socialDescription = description;
};

export const setSocialTitle = (title) => {
  socialTitle = title;
};

export const shareToFacebook = () => {
  const {postToFB} = window.DMS.Facebook;

  if (!postToFB) {
    return;
  }

  postToFB(POSITION_NAME, {forcedUrl: getArticleUrl()});
};

export const shareToTwitter = () => {
  const {postToTWTTR} = window.DMS.Twitter;

  if (!postToTWTTR) {
    return;
  }

  postToTWTTR(POSITION_NAME, {forcedUrl: getArticleUrl()});
};

export const shareToPinterest = () => {
  const {postToPinterest} = window.DMS.Pinterest;

  if (!postToPinterest) {
    return;
  }

  postToPinterest(POSITION_NAME, {forcedUrl: getArticleUrl()});
};

export const shareToEmail = () => {
  const mailTo = window.DMS.Email.buildMailtoLink('email-plain-text', 'gallery', socialTitle, socialDescription, getArticleUrl(false), '#gallery');

  window.location.replace(mailTo);
  window.DMS.Email.emailArticle('gallery');
};

import {later} from '@mol-fe/mol-fe-async';
import {getConfig} from '@mol-fe/mol-fe-components';
import {logger} from '@mol-fe/mol-fe-client-logger';
import navigation from './previousNextNavigation';

const loaded = Promise
  .all([
    getConfig('molFePrevNextNavigation'),
    later('DOM_READY')
  ])
  .then(([config]) => navigation.init(config))
  .catch((error) => {
    logger.error('Error during mol-fe-previous-next-navigation initialisation: ', error);
  });

// eslint-disable-next-line import/prefer-default-export
export {loaded};

/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getGalleryItems, clearGalleryItemsCache} from './src/getGalleryItems';
import {attachGalleryItemHandlers} from './src/attachGalleryItemHandlers';
import {openGallery, closeGallery} from './src/mount';
import {addBeforeOpenHandler} from './src/beforeOpen';

export const init = ({allowVideos = true} = {}) => {
  try {
    const {articleType, pageType, enhancedGallery} = getPageCriteria();
    const {renderPlatform} = getPageMetadata();
    const enabledFromQueryString = window.location.search && window.location.search.match(/debugEnhancedGallery=true/);

    // Check enhanced gallery is enabled
    if (!enhancedGallery && !enabledFromQueryString) {
      return;
    }

    // Check that we're on a valid article
    if (pageType !== 'article' || articleType === 'feature' && renderPlatform === 'desktop') {
      return;
    }

    clearGalleryItemsCache();

    // Get all the gallery items from the article, abort if none found
    const galleryItems = getGalleryItems();

    if (!galleryItems || !galleryItems.length) {
      return;
    }

    const itemCount = galleryItems.length;

    // Bind click handlers to the images, plus add the overlay button to open the gallery to them
    galleryItems.forEach((item) => attachGalleryItemHandlers(item, {
      allowVideos,
      itemCount
    }));

    const startOpen = location.hash === '#gallery';

    if (startOpen) {
      openGallery({allowVideos});
    }
  } catch (error) {
    logger.error('Error initialising enhanced image gallery', error);
  }
};

export {openGallery, closeGallery, addBeforeOpenHandler};

init();

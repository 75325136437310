const STORE_KEY = 'mol-fe-gallery/articles';
const SEPARATOR = ',';
const CACHE_COUNT = 10;
let articlesCache;

const getStore = () => {
  try {
    return window.localStorage.getItem(STORE_KEY);
  } catch (error) {
    return '';
  }
};

const setStore = (value) => {
  try {
    window.localStorage.setItem(STORE_KEY, value);

  // eslint-disable-next-line no-empty
  } catch (error) {}
};

const getArticles = () => {
  if (!articlesCache) {
    const store = getStore();

    articlesCache = store && store.length && store.split(SEPARATOR).map((articleId) => parseInt(articleId, 10)) || [];
  }

  return [...articlesCache];
};

const saveArticles = (items) => {
  articlesCache = items;
  setStore(articlesCache.join(SEPARATOR));
};

const addArticle = (id) => {
  const articleId = parseInt(id, 10);
  const articles = getArticles();

  if (!articleId || articles.includes(articleId)) {
    return;
  }

  articles.push(articleId);

  while (articles.length > CACHE_COUNT) {
    articles.shift();
  }

  saveArticles(articles);
};

export default {
  addArticle,
  getArticles,
  saveArticles
};


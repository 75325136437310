/* eslint-disable complexity */
import React, {useEffect} from 'react';
import classnames from 'classnames';
import styles from './styles.scss';

export const Controls = ({articleTitle, itemCount, currentIndex, caption, disabled, hasNextGallery, hasPreviousGallery, onClose, onNext, onPrevious, showControls, mobileMode = false}) => {
  useEffect(() => {
    if (disabled) {
      return;
    }

    const keyHandler = (event) => {
      if (event.key === 'ArrowRight') {
        onNext();
      } else if (event.key === 'ArrowLeft') {
        onPrevious();
      }
    };

    document.addEventListener('keyup', keyHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keyup', keyHandler);
    };
  }, [disabled, currentIndex, itemCount, hasNextGallery, hasPreviousGallery, onNext, onPrevious]);

  return [
    showControls && currentIndex < itemCount - 1 && <div className={classnames(styles.topBar, mobileMode && styles.mobileMode)} key='top'>
      <div className={styles.counter}>{currentIndex + 1} of {itemCount}</div>
    </div>,
    showControls && <button aria-label='Close' className={classnames(styles.closeButton, mobileMode && styles.mobileMode)} key='close' onClick={onClose} />,
    <div className={classnames(styles.bottomBar, caption && styles.hasCaption, mobileMode && styles.mobileMode)} key='bottom'>
      {currentIndex === 0 && articleTitle && hasPreviousGallery &&
        <div className={styles.articleTitle}>NEW ARTICLE: {articleTitle}</div>
      }
      {caption && <div className={styles.caption}>{caption}</div>}
      {showControls && <div className={styles.paginationButtons}>
        <button aria-label='Previous' className={styles.previousButton} disabled={currentIndex < 1 && !hasPreviousGallery || disabled} onClick={onPrevious} />
        <button aria-label='Next' className={styles.nextButton} disabled={currentIndex >= itemCount - 1 && !hasNextGallery || disabled} onClick={onNext} />
      </div>}
    </div>
  ];
};

import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import {mountAd, unmountAd, updateAd} from '../../api/ads';
import styles from './styles.scss';

export const AdSlot = ({position, slotId, slotType, size, variant}) => {
  const sizeRef = useRef(size);

  useEffect(() => {
    mountAd(slotId, position, variant);

    return () => {
      unmountAd(slotId, position, variant);
    };
  }, []);

  useEffect(() => {
    if (sizeRef.current !== size) {
      updateAd(slotId, position, variant);
    }

    sizeRef.current = size;
  }, [size]);

  return (
    <div className={classnames(styles[slotType], size && styles[size], styles.adSlot)}>
      <div className={styles.inner} id={slotId} />
    </div>
  );
};

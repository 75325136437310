import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {bindButtons} from '@mol-fe/mol-fe-social/src/desktop/article';
import {updateArticleCount} from '@mol-fe/mol-fe-floating-buttons/src/articleCount';
import {createFloatingBarContainer} from './htmlGenerator';
import {handleFloatingBar} from './client';

export const show = () => {
  try {
    // change when variable is available
    const newArticleRefresh = 10000;

    createFloatingBarContainer();
    handleFloatingBar();
    const floatingBar = document.getElementById('floating-share-bar-container');

    bindButtons(floatingBar, 'floatingbar');

    // setInitialArticleCounts(0); set initial when variable is available
    updateArticleCount(true, floatingBar);
    setInterval(
      () => updateArticleCount(true, floatingBar),
      parseInt(newArticleRefresh, 10) || 1000
    );
  } catch (error) {
    logger.error(
      `Failed to initialize mol-fe-floating-share-bar: ${error}`,
      error
    );
  }
};

export const init = async () => {
  const {enableFloatingShareBar, pageType} = getPageCriteria();
  const {renderPlatform, topics} = getPageMetadata();

  if (
    window.location.search &&
      window.location.search.match(/debugFloatingShareBar=true/) ||
    enableFloatingShareBar &&
      pageType === 'article' &&
      renderPlatform === 'desktop' &&
      !topics.includes('Mail Best') &&
      !topics.includes('Mail Best US')
  ) {
    return show();
  }

  return null;
};

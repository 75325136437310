// eslint-disable-next-line filenames/match-exported
import {later} from '@mol-fe/mol-fe-async';
import * as Rotator from '../../';
import initAutoInitRotators from './initAutoInitRotators';

(async () => {
  await later('DOM_READY');
  initAutoInitRotators();
})();

export default Rotator;

/* eslint-disable complexity */
import React, {useEffect, useState, useRef} from 'react';
import classnames from 'classnames';
import {shareToEmail, shareToFacebook, shareToPinterest, shareToTwitter} from '../../api/social';
import {trackGalleryView} from '../../api/tracking';
import {getRealRenderHeight} from '../../helpers';
import {VideoSlide} from '../VideoSlide';
import {ZoomableImage} from '../ZoomableImage';
import {Spinner} from '../Spinner';
import styles from './styles.scss';

export const VerticalSlide = ({galleryItem, totalCount}) => {
  const [hasBeenActive, setHasBeenActive] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const [hasBeenInViewportTimes, setHasBeenInViewportTimes] = useState(0);
  const slideRef = useRef(null);

  useEffect(() => {
    if (isInViewport) {
      if (galleryItem && galleryItem.index > 0) {
        trackGalleryView(galleryItem);
      }

      setHasBeenActive(true);
      setHasBeenInViewportTimes((times) => times + 1);
    }
  }, [isInViewport]);

  useEffect(() => {
    if (hasBeenInViewportTimes > 1 && galleryItem && galleryItem.index === 0) {
      trackGalleryView(galleryItem);
    }
  }, [hasBeenInViewportTimes]);

  useEffect(() => {
    if (!slideRef.current) {
      return;
    }

    const handleIntersect = (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '200px'
    });

    observer.observe(slideRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, []);

  const caption = galleryItem && galleryItem.type === 'image' && galleryItem.element.alt;
  const isLandscape = window.innerWidth > window.innerHeight;
  const windowWidth = isLandscape ? 420 : window.innerWidth;
  const realImageHeight = `${getRealRenderHeight(galleryItem.element, windowWidth - 20)}px`;

  return (
    <div className={classnames(styles.slide)} ref={slideRef}>
      {galleryItem.type === 'videoimage' && <VideoSlide galleryItem={galleryItem} isActive={isInViewport} />}
      {galleryItem.type === 'image' &&
        <div className={styles.placeholder} style={{height: realImageHeight}}>
          {hasBeenActive ?
            <ZoomableImage
              imageUrl={galleryItem.element.dataset.gallerySrc || galleryItem.element.dataset.src || galleryItem.element.src}
              zoomableMode={true}
            /> :
            <Spinner />
          }
        </div>
      }
      {galleryItem.type !== 'videoimage' &&
        <div className={styles.info}>
          {caption && <div className={styles.caption}>{caption}</div>}
          <div className={styles.actionBar}>
            <div className={styles.counter}>{galleryItem.index + 1}/{totalCount}</div>
            <div className={styles.shareButtons}>
              <button aria-label='Share on Facebook' className={styles.facebookButton} onClick={shareToFacebook} />
              <button aria-label='Share on Twitter' className={styles.twitterButton} onClick={shareToTwitter} />
              <button aria-label='Share on Pinterest' className={styles.pinterestButton} onClick={shareToPinterest} />
              <button aria-label='Share on Email' className={styles.emailButton} onClick={() => shareToEmail()} />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

/**
 * Used to add an event listener for keydown
 * event that will invoke the callback when
 * the target keyCode character is pressed.
 *
 * Ignore events that occur when document.activeElement
 * is an input element.
 */
export default (keyCode, callback) => {
  const ignoreNodeNames = /^(?:input|select|textarea)$/i;

  document.addEventListener('keydown', (event) => {
    // Disable the keydown navigation if current active element is an input.
    // Second part of if. If full screen gallery is open don't do navigation
    if (ignoreNodeNames.test(document.activeElement.nodeName) ||
        document.body.classList.contains('video-js-fullscreen') ||
        document.body.classList.contains('mobile-gallery-open')) {
      return;
    }

    const eventKey = event.key || event.keyCode;

    if (!keyCode.includes(eventKey)) {
      return;
    }

    callback();
  });
};

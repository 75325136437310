import React from 'react';
import styles from './styles.scss';

export const ImageBox = ({image, ratio}) => {
  const style = {paddingBottom: `${ratio * 100}%`};

  if (image) {
    style.backgroundImage = `url('${image}')`;
  }

  return (
    <div className={styles.imageBox} style={style} />
  );
};

const addListeners = (browser, element, events, onResize) => {
  if (browser.addEventListener) {
    // set touchstart event on element
    if (browser.touch) {
      element.addEventListener('touchstart', events, false);
    }

    if (browser.pointer) {
      element.addEventListener('MSPointerDown', events, false);
    }

    if (browser.transitions) {
      element.addEventListener('webkitTransitionEnd', events, false);
      element.addEventListener('msTransitionEnd', events, false);
      element.addEventListener('oTransitionEnd', events, false);
      element.addEventListener('otransitionend', events, false);
      element.addEventListener('transitionend', events, false);
    }

    // set resize event on window
    window.addEventListener('resize', events, false);
    window.addEventListener('orientationchange', events, false);
  } else {
    window.onresize = function () {
      onResize();
    };
  }
};

const removeListeners = (browser, element, events) => {
  if (browser.addEventListener) {
    // remove current event listeners
    element.removeEventListener('touchstart', events, false);
    element.removeEventListener('MSPointerDown', events, false);
    element.removeEventListener('webkitTransitionEnd', events, false);
    element.removeEventListener('msTransitionEnd', events, false);
    element.removeEventListener('oTransitionEnd', events, false);
    element.removeEventListener('otransitionend', events, false);
    element.removeEventListener('transitionend', events, false);
    window.removeEventListener('resize', events, false);
    window.removeEventListener('orientationchange', events, false);
  } else {
    window.onresize = null;
  }
};

const removeTouchListeners = (browser, element, events) => {
  if (browser.pointer) {
    element.removeEventListener('MSPointerMove', events, false);
    element.removeEventListener('MSPointerUp', events, false);
  } else {
    element.removeEventListener('touchmove', events, false);
    element.removeEventListener('touchend', events, false);
  }
};

export {
  addListeners,
  removeListeners,
  removeTouchListeners
};

/* eslint-disable complexity */
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {VideoSlide} from '../VideoSlide';
import {Spinner} from '../Spinner';
import {ZoomableImage} from '../ZoomableImage';
import {AdSlot} from '../AdSlot';
import styles from './styles.scss';

const SUPPORTS_OBJECT_FIT = (() => {
  try {
    return Boolean('objectFit' in document.documentElement.style);
  } catch (error) {
    // Error = not supported
  }

  return false;
})();

export const GallerySlide = ({galleryItem, isActive, isNextSlide, mobileMode, onToggleControls}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const {isTablet} = getPageCriteria();
  const zoomEnabled = mobileMode || isTablet;

  useEffect(() => {
    if (isActive || isNextSlide) {
      setHasLoaded(true);
    }
  }, [isActive, isNextSlide]);

  if (galleryItem.type === 'videoimage') {
    return (
      <div className={styles.slide}>
        <VideoSlide galleryItem={galleryItem} isActive={isActive} mobileMode={mobileMode} />
      </div>
    );
  }

  if (galleryItem.type === 'image') {
    const imageSrc = galleryItem.element.dataset.gallerySrc || galleryItem.element.dataset.src || galleryItem.element.src;
    const imageComponent = zoomEnabled && isActive ? <ZoomableImage
      imageUrl={imageSrc}
      onClickImage={onToggleControls}
    /> : <img
      className={classnames(styles.image, SUPPORTS_OBJECT_FIT && !mobileMode && styles.fitImage)}
      onClick={onToggleControls}
      src={imageSrc}
    />;

    return (
      <div className={styles.slide}>
        {hasLoaded ? imageComponent : <Spinner />}
      </div>
    );
  }

  if (galleryItem.type === 'interstitial') {
    return (
      <div className={styles.slide}>
        <AdSlot position='mobile_gallery' slotId={galleryItem.positionId} slotType='mpu' variant={galleryItem.variant} />
      </div>
    );
  }

  return null;
};

import {pageEvents} from '@mol-fe/mol-fe-tracking';
import scrollWindowTo from '@mol-fe/mol-fe-floating-buttons/src/scrollWindowTo';

const addClickHandlers = (floatingButtons) => {
  const backToTopButton = floatingButtons.querySelector('.back-to-top');
  const backToHomeButton = floatingButtons.querySelector('.back-to-home');

  if (backToTopButton) {
    backToTopButton.addEventListener('click', (event) => {
      event.preventDefault();
      pageEvents.publish(pageEvents.FLOATING_BUTTONS_BACK_TO_TOP, {
        value: 'top_sharebar'
      });
      scrollWindowTo(0);
    });
  }

  if (backToHomeButton) {
    backToHomeButton.addEventListener('click', () => {
      pageEvents.publish(pageEvents.FLOATING_BUTTONS_BACK_TO_TOP, {
        value: 'home_sharebar'
      });
    });
  }
};

export default addClickHandlers;

import {isAndroidStock} from '@mol-fe/mol-fe-mobileutils';

let mvp;

const disable = () => {
  // this is for desktop only ...
  // but for some reason it doesn't work with android browser
  // per se it should work. It is not working together with gunther,
  // maybe for some weird bug
  if (!isAndroidStock() && !document.querySelector('meta[name=viewport]')) {
    mvp = document.createElement('meta');
    mvp.setAttribute('name', 'viewport');
    mvp.setAttribute('content', 'user-scalable=0');
    document.head.appendChild(mvp);
  }
};

const restore = () => {
  if (!isAndroidStock() && mvp && mvp.parentNode) {
    mvp.setAttribute('content', 'user-scalable=1');
    setTimeout(() => {
      if (mvp && mvp.parentNode) {
        mvp.parentNode.removeChild(mvp);
      }
    }, 0);
  }
};

export {
  disable,
  restore
};

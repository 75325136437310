/* eslint-disable react/jsx-no-bind */
import react from '@mol-fe/mol-fe-react';
import ImageBox from '../ImageBox';
import styles from './styles.scss';

const {React, PropTypes} = react;

const Recommendations = (props) => {
  const {items} = props;

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.heading}>More galleries</div>
        <div className={styles.items} data-track-module='gallery-recommendations^gallery'>
          {items.map(({images = [], imageCount, headline, articleId, articleUrl}, index) =>
            <a className={styles.item} data-allow-propagation={true} href={`${articleUrl}#gallery`} key={articleId || index}>
              <div className={styles.topSection}>
                <div className={styles.itemImage}>
                  <ImageBox image={images[0]} ratio={0.60177} />
                </div>
              </div>
              <div className={styles.bottomSection}>
                <div className={styles.itemImage}>
                  <ImageBox image={images[1]} ratio={0.73333} />
                </div>
                <div className={styles.itemImage}>
                  <ImageBox image={images[2]} ratio={0.73333} />
                </div>
                <div className={styles.itemImageCount}>{imageCount ? `+ ${imageCount}` : '...'}</div>
              </div>
              <div className={styles.headline}>{headline}</div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

Recommendations.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    headline: PropTypes.string,
    imageCount: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.object)
  })).isRequired
};

export default Recommendations;

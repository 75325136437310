import {debounce} from '@mol-fe/mol-fe-mini-lodash';
import onKeydown from './utils/onKeydown';
import navigateTo from './utils/navigateTo';
import {createNavigationElement} from './utils/createNavigationElement';
import {hasAdverts, onAdRender, isSkinAdPresent, getAds, updateWidestSideAdWidth} from './utils/adverts';
import {screenBreakPoint, calculateAvailableWidth} from './utils/screen';
import {getElementWidth} from './utils/element';

const KEYCODES = {
  LEFT: [
    37,
    'ArrowLeft',
    'Left'
  ],
  RIGHT: [
    39,
    'ArrowRight',
    'Right'
  ]
};

const init = function (options) {
  if (!options.prevArticle && !options.nextArticle) {
    throw new Error('Missing required options to init Mol Fe Previous Next Navigation');
  }

  if (window.disablePreviousNextNavigation) {
    return null;
  }

  /**
   * Width of the widest sidebar ad on the page.
   */
  let previousButtonElement;
  let nextButtonElement;

  const toggleNavigationVisibility = (show) => {
    if (previousButtonElement) {
      previousButtonElement.style.display = show ? 'block' : 'none';
    }

    if (nextButtonElement) {
      nextButtonElement.style.display = show ? 'block' : 'none';
    }
  };

  /**
   * Toggles the prev, next navigation depending
   * on whether there is enough space to display
   * the navigation without overlaying it on top
   * of visible sidebar ads.
   */
  const updateNavigationVisibility = () => {
    if (options.forceOnTopOfAds) {
      toggleNavigationVisibility(screenBreakPoint());

      return;
    }

    const isAdPresent = hasAdverts() && isSkinAdPresent();
    const availableWidth = calculateAvailableWidth() / 2;
    const buttonWidth = getElementWidth(previousButtonElement || nextButtonElement);
    const showNavigation = availableWidth >= buttonWidth;

    toggleNavigationVisibility(showNavigation && !isAdPresent);
  };

  if (options.prevArticle) {
    const navigateToPreviousArticle = () => {
      if (document.body.classList.contains('enhanced-gallery-open')) {
        return;
      }

      navigateTo(options.prevArticle.url, previousButtonElement);
    };

    previousButtonElement = createNavigationElement('prev', navigateToPreviousArticle);
    onKeydown(KEYCODES.LEFT, navigateToPreviousArticle);
  }

  if (options.nextArticle) {
    const navigateToNextArticle = () => {
      if (document.body.classList.contains('enhanced-gallery-open')) {
        return;
      }

      navigateTo(options.nextArticle.url, nextButtonElement);
    };

    nextButtonElement = createNavigationElement('next', navigateToNextArticle);
    onKeydown(KEYCODES.RIGHT, navigateToNextArticle);
  }

  if (hasAdverts()) {
    // New add loaded.
    onAdRender((adDefinition) => {
      updateWidestSideAdWidth(adDefinition);
      updateNavigationVisibility();
    });

    // Page load.
    getAds().forEach(updateWidestSideAdWidth);

    // Page resize.
    window.addEventListener('resize', debounce(() => {
      getAds().forEach(updateWidestSideAdWidth);
      updateNavigationVisibility();
    }), 150);
  }

  updateNavigationVisibility();

  return {
    nextButton: nextButtonElement,
    previousButton: previousButtonElement
  };
};

export default {
  init
};


let beforeOpenQueue = [];

export const addBeforeOpenHandler = (handler) => {
  beforeOpenQueue.push(handler);
};

export const runBeforeOpenHandlers = () => {
  try {
    if (beforeOpenQueue && beforeOpenQueue.length) {
      // Things that need to happen synchronously before building slides
      for (const handler of beforeOpenQueue) {
        handler();
      }
      beforeOpenQueue = [];
    }
  } catch (error) {
    // Ignore errors
  }
};

import {getZoom} from '@mol-fe/mol-fe-mobileutils';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import ShareSlideManager from '../utils/ShareSlideManager';
import ArticleGallery from '../ArticleGallery';
import {indexOf} from './helpers';

const shareSlideManager = new ShareSlideManager();

const galleryImages = (startImage, images) => {
  const result = [];
  const start = indexOf(startImage, images);
  const length = images.length;

  Array.from(images).forEach((element, idx) => {
    element.setAttribute('data-track-pos', idx);
  });

  for (let end = start + length, idx = start; idx < end; idx++) {
    result.push(images[idx % length]);
  }

  return result;
};

const openGallery = (image, images) => {
  const PageCriteria = getPageCriteria();

  if (PageCriteria.isMobile && getZoom() > 1) {
    return false;
  }

  const target = document.querySelector('#mobileGalleryModal');

  const articleGallery = new ArticleGallery({
    images: [
      ...galleryImages(image, images),
      shareSlideManager.getElement()
    ],
    target
  });

  Array.from(document.querySelectorAll('.mobile-gallery-instruction')).forEach((element) => {
    element.style.display = 'none';
  });
  articleGallery.show(image);

  return true;
};

export default openGallery;

import slugify from 'slugify';

const MAX_SLUG_LENGTH = 50;

export const setDataTrackModuleOnFactbox = (element) => {
  let trackModule = 'factbox';

  const titleEl = element.querySelector('.mol-factbox-title');

  if (titleEl) {
    const title = titleEl.innerText.trim();
    const slug = slugify(title, {
      lower: true,
      strict: true
    }).substring(0, MAX_SLUG_LENGTH);

    trackModule = `${trackModule}^factbox-${slug}`;
  }

  element.dataset.trackModule = trackModule;
};

import {onTouchEnd} from '@mol-fe/mol-fe-mobileutils';
import {later} from '@mol-fe/mol-fe-async';
import {supported, trackZoom} from './helpers';
import init from './init';

const ArticlePage = function (options = {}) {
  if (!supported()) {
    return;
  }
  this.bind(options);
};

ArticlePage.prototype.bind = function (options = {}) {
  init(options);

  if (!options || !options.mobile) {
    window.addEventListener('touchend', () => {
      onTouchEnd(trackZoom);
    });
  }
};

const hideImageOnMouseOverWrap = () => {
  Array.from(document.querySelectorAll('.image-wrap')).forEach((imageWrapElement) => {
    imageWrapElement.addEventListener('mouseenter', () => {
      Array.from(document.querySelectorAll('.image-share-url-lightbox')).forEach((urlElement) => {
        urlElement.style.display = 'none';
      });
    });
  });
};

later('DOM_READY', hideImageOnMouseOverWrap);

export default ArticlePage;

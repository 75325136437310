/**
 * Toggles the visibility of a DOM element based on the current scroll position,
 * the element's position in the viewport, and the body's background image.
 *
 * @param {Object} state - The state object.
 * @param {HTMLElement} state.el - The DOM element to toggle.
 * @param {HTMLElement} [state.showAt] - The DOM element at which to start showing state.el. Defaults to the first div with class 'shareArticles'.
 * @param {HTMLElement} [state.container] - The container of state.el. Defaults to the element with id 'content'.
 */
export const toggle = (state) => {
  const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  state.showAt = state.showAt || document.querySelector('div.shareArticles');
  state.container = state.container || document.getElementById('content');

  if (state.showAt) {
    if (window.scrollY + h >= state.showAt.getBoundingClientRect().top &&
        h >= state.el.offsetHeight &&
        state.container.getBoundingClientRect().left >= state.el.offsetWidth + 15 &&
        w >= state.el.offsetHeight &&
        window.getComputedStyle(document.body).backgroundImage === 'none') {
      state.el.classList.add('show');
    } else {
      state.el.classList.remove('show');
    }
  }
};

/**
 * Initialises the scroll event listener to toggle the visibility of a DOM element.
 *
 * @param {Object} obj - The object containing the id of the DOM element.
 * @param {string} obj.id - The id of the DOM element to toggle.
 */
export const init = (obj) => {
  const el = document.getElementById(obj.id);

  if (!el) {
    return;
  }

  const state = {el};

  window.addEventListener('scroll', () => toggle(state));
};

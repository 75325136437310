import react from '@mol-fe/mol-fe-react';
import {bindButtons} from '@mol-fe/mol-fe-social/src/desktop/article';
import {wrapContainerContent} from '@mol-fe/mol-fe-social/src/mobile';
import styles from './styles.scss';

const {React, PropTypes} = react;

class SocialButtons extends React.Component {
  static propTypes = {
    position: PropTypes.string
  };

  static defaultProps = {
    position: 'image_gallery-last'
  };

  componentDidMount () {
    try {
      if (window.DM && window.DM.getPageMetadata && window.DM.getPageMetadata().renderPlatform === 'mobile') {
        this.initMobile();
      } else {
        this.initDesktop();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Was not able to mount SocialButtons', error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  shouldComponentUpdate () {
    return false;
  }

  replaceItoInHref (href, platform) {
    return href.replace(/(ito%3D)[a-zA-Z_-]*/g, `$1${platform}_share_article-${this.props.position}`);
  }

  replaceItoInText (text, platform) {
    return text.replace(/([?&]ito=)[a-zA-Z_-]*/g, `$1${platform}_share_article-${this.props.position}`);
  }

  initPlatforms ({platforms, linksElement, platformLinkSelector}) {
    platforms.forEach((platform) => {
      try {
        const elements = linksElement.querySelectorAll(platformLinkSelector(platform));
        const element = Array.from(elements).filter((el) => el.style.display !== 'none')[0];
        const newElement = document.createElement('div');

        newElement.className = `${styles[platform]}`;

        if (element) {
          try {
            const replacedWith = document.createElement('div');

            replacedWith.className = element.className;
            replacedWith.innerHTML = element.innerHTML;

            Array.from(element.attributes).forEach(({name, value}) => {
              if (name === 'data-text' && value) {
                replacedWith.setAttribute(name, this.replaceItoInText(value, platform));
              }
            });

            if (platform === 'email') {
              const anchor = replacedWith.querySelector('a');

              anchor.href = this.replaceItoInHref(anchor.href, platform);
            }

            newElement.appendChild(replacedWith);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Could not parse platform ${platform} social link`);
          }
        }

        this.element.appendChild(newElement);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Could not find social platform ${platform}`, error);
      }
    });
  }

  initMobile () {
    const platforms = ['twitter', 'whatsapp', 'email'];

    if (window.PageCriteria && window.PageCriteria.hideFacebook) {
      platforms.push('sms');
    } else {
      platforms.unshift('facebook');
    }

    this.initPlatforms({
      linksElement: document.querySelector('.social.top'),
      platformLinkSelector: (platform) => `.${platform}`,
      platforms
    });

    wrapContainerContent(this.element, `.${styles.icon} a`);
  }

  initDesktop () {
    const platforms = ['twitter', 'pinterest', 'email'];

    if (!window.PageCriteria || !window.PageCriteria.hideFacebook) {
      platforms.unshift('facebook');
    }

    this.initPlatforms({
      linksElement: document.querySelector('#articleIconLinksContainer'),
      platformLinkSelector: (platform) => `[class^="share-${platform}"]`,
      platforms
    });

    bindButtons(this.element, this.props.position);
  }

  setElement = (ref) => {
    this.element = ref;
  };

  render () {
    return (
      <div className={styles.container} data-location={this.props.position} ref={this.setElement} />
    );
  }
}

export default SocialButtons;

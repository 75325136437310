import {later} from '@mol-fe/mol-fe-async';
import {trackZoom} from './helpers';
import processImage from './processImage';
import openGallery from './openGallery';

let MIN_IMAGE_WIDTH;
let MIN_IMAGE_HEIGHT;

const byLargeArticleImage = (imageElement) => {
  if (imageElement.tagName !== 'IMG') {
    return true;
  }

  const height = imageElement.height || parseInt(imageElement.getAttribute('height'), 10);
  const width = imageElement.width || parseInt(imageElement.getAttribute('width'), 10);

  return (
    height > MIN_IMAGE_HEIGHT + 70 || height < MIN_IMAGE_HEIGHT + 70 &&
    height >= MIN_IMAGE_HEIGHT &&
    width > MIN_IMAGE_WIDTH + 90
  );
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const init = async (initConfig) => {
  const mobile = initConfig.mobile;
  const config = typeof initConfig === 'undefined' ? {} : initConfig;
  let selector = 'img[id^=i-]';

  MIN_IMAGE_WIDTH = mobile ? 200 : 300;
  MIN_IMAGE_HEIGHT = mobile ? 150 : 300;

  if (mobile && config.allowVideos) {
    selector = 'img[id^=i-],div[id^=v-]';
  }

  if (mobile) {
    await Promise.race([delay(5000), later('FIRST_IDLE')]);
  }

  trackZoom();

  // include large enough images and videos if required
  // but deal with images only for now
  const images = Array.from(document.querySelectorAll(selector))
    .filter((imageElement) => byLargeArticleImage(imageElement));

  images
    .filter((imageElement) => imageElement.tagName === 'IMG' && !imageElement.closest('.mol-product'))
    .forEach((imageElement, idx) => processImage(imageElement, idx + 1, images));

  if (location.hash === '#gallery') {
    let startGalleryCount = 0;
    let startGalleryTimeoutId = 0;
    const {pathname, search} = window.location;
    const autoOpenGallery = () => {
      if (openGallery(images[0], images)) {
        setTimeout(() => {
          const resizeEvent = window.document.createEvent('UIEvents');

          resizeEvent.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(resizeEvent);
        }, 100);
      } else {
        clearTimeout(startGalleryTimeoutId);

        if (startGalleryCount++ <= 10) {
          startGalleryTimeoutId = setTimeout(autoOpenGallery, 100);
        }
      }
    };

    await later('ARTICLE_DEFER_BUNDLE_LOADED');

    autoOpenGallery();

    history.replaceState('', document.title, `${pathname}${search}`);
  }
};

export default init;

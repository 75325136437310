const createLinkHandler = (element) => ({
  preventDefault (event) {
    event.preventDefault();
  },
  toggleOffPreventClick () {
    const links = element.getElementsByTagName('a');

    for (let idx = 0; idx < links.length; idx++) {
      links[idx].removeEventListener('click', this.preventDefault, false);
    }
  },
  toggleOnPreventClick () {
    const links = element.getElementsByTagName('a');

    for (let idx = 0; idx < links.length; idx++) {
      links[idx].addEventListener('click', this.preventDefault, false);
    }
  }
});

export default createLinkHandler;

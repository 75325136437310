import {isEmpty} from './helpers';
import {removeContainer} from './slides';

const ALLOWED_ADS_HTML_ELEMENTS = ['div', 'span', 'img', 'iframe'];

const isValid = (advert) => {
  if (isEmpty(window.AdIntegrator.getAdContainer(advert).querySelector('[data-impression-url]'))) {
    return false;
  }

  if (advert.style.display === 'none') {
    return false;
  }

  const advertContent = Array.from(advert.children);

  const valid = advertContent.some((content) =>
    ALLOWED_ADS_HTML_ELEMENTS.includes(content.tagName.toLowerCase()) &&
    !content.classList.contains('adLinkLabel')
  );

  return valid;
};

const hasAdLoaded = (container) => container && container.classList.contains('advert-loaded');

const isAdSlide = (container) => container && container.classList.contains('advert');

const updateAdverts = (advertSlides, onAdvertLoaded, gallery) => {
  const advertContainers = advertSlides.map((slide) => slide.closest('.slide-container'));

  advertContainers.forEach((container) => container.classList.add('loading'));

  if (document.body.classList.contains('molads_interstitial_off')) {
    advertSlides.forEach((slide) => {
      const parent = slide.parentNode;

      if (parent && parent.parentNode) {
        parent.parentNode.removeChild(parent);
      }
    });
  } else if (window.useGpt) {
    const renderer = window.adsRenderer;
    const adListLoader = window.adListLoader;

    if (renderer && adListLoader && adListLoader.isAdEnabled({pos: 'interstitial'})) {
      renderer.addLoadListener(onAdvertLoaded);
      advertSlides.forEach((advert) => {
        renderer.requestDelayedAdvert(advert.id);
      });
    } else {
      advertContainers.forEach((container) => {
        if (container) {
          removeContainer(container, gallery);
        }
      });
    }
  } else {
    advertSlides.forEach((advert) => {
      if (!isValid(advert)) {
        advert.parentNode.parentNode.removeChild(advert.parentNode);
      }
    });
  }
};

const isAdIframe = (element) =>
  element.id && element.id.slice(0, 18) === 'google_ads_iframe_' && element.width !== '0';

const getAdvertSlides = () => Array.from(document.querySelectorAll('.slide-container.advert .slide'));

export {
  isValid,
  hasAdLoaded,
  isAdSlide,
  updateAdverts,
  isAdIframe,
  getAdvertSlides
};

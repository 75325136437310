/* eslint-disable complexity */
import React, {useRef, useCallback, useEffect} from 'react';
import {useSwipeable} from 'react-swipeable';
import {GallerySlide} from '../GallerySlide';
import {ShareSlide} from '../ShareSlide';
import styles from './styles.scss';

export const HorizontalSlider = ({galleryItems, currentIndex, mobileMode, onToggleControls, onGetNextGallery, onGoToNextGallery, onGoToPreviousGallery, onNext, onPrevious, articleTitle}) => {
  const sliderRef = useRef(null);
  const onSwiping = useCallback(({deltaX}) => {
    const {current: sliderEl} = sliderRef;
    const left = `calc(-${currentIndex * 100}% + ${-deltaX}px)`;

    sliderEl.style.transition = 'none';
    sliderEl.style.left = left;
  }, [currentIndex]);
  const onSwiped = useCallback(({absX, velocity, dir}) => {
    const {current: sliderEl} = sliderRef;

    sliderEl.style.transition = '';

    if (
      !['Right', 'Left'].includes(dir) ||
      absX < 150 && velocity < 1 ||
      currentIndex <= 0 && dir === 'Right' && !onGoToPreviousGallery ||
      currentIndex >= galleryItems.length && dir === 'Left' && !onGoToNextGallery
    ) {
      sliderEl.style.left = `-${currentIndex * 100}%`;

      return;
    }

    if (dir === 'Right') {
      onPrevious();
    } else if (dir === 'Left') {
      onNext();
    }
  }, [currentIndex, galleryItems, onGoToNextGallery, onGoToPreviousGallery]);
  const swipeHandlers = useSwipeable({
    onSwiped,
    onSwiping,
    preventDefaultTouchmoveEvent: true
  });

  useEffect(() => {
    sliderRef.current.style.left = `-${currentIndex * 100}%`;
  }, [currentIndex]);

  return (
    <div className={styles.scrollWrapper} {...swipeHandlers}>
      <div className={styles.scrollingElement} ref={sliderRef}>
        {galleryItems.map((galleryItem) =>
          <GallerySlide
            galleryItem={galleryItem}
            isActive={currentIndex === galleryItem.index}
            isNextSlide={currentIndex + 1 === galleryItem.index}
            key={galleryItem.type + galleryItem.index}
            mobileMode={mobileMode}
            onToggleControls={onToggleControls}
          />
        )}
        <ShareSlide
          articleTitle={articleTitle}
          isActive={currentIndex >= galleryItems.length - 1}
          mobileMode={mobileMode}
          onGetNextGallery={onGetNextGallery}
          onGoToNextGallery={onGoToNextGallery}
        />
      </div>
    </div>
  );
};

import {later} from '@mol-fe/mol-fe-async';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {loaded as floatingShareBarLoaded} from '@mol-fe/mol-fe-floating-share-bar';
import closeIcon from './assets/close-icon.svg';
// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';

const getArticleSummaryBlock = () => {
  const {articleTitle} = getPageMetadata();
  let articleImgUrl;

  if (document.querySelector('meta[itemprop="thumbnailurl"]')) {
    articleImgUrl = document.querySelector('meta[itemprop="thumbnailurl"]').content;
  } else if (document.querySelector('meta[property="og:image"]')) {
    articleImgUrl = document.querySelector('meta[property="og:image"]').content;
  }

  const articleSummaryBlock = document.createElement('div');
  const articleSummaryText = document.createElement('div');
  const articleSummaryCloseBtn = document.createElement('button');
  const articleSummaryImage = document.createElement('img');

  articleSummaryBlock.id = 'article-summary-block';
  articleSummaryText.id = 'article-summary-text';
  articleSummaryText.innerHTML = articleTitle;
  articleSummaryCloseBtn.id = 'article-summary-close-btn';
  articleSummaryCloseBtn.innerHTML = `<img src=${closeIcon} alt="Close" width="18px" height="18px"/>`;
  articleSummaryImage.id = 'article-summary-image';
  articleSummaryImage.src = articleImgUrl;

  if (articleImgUrl) {
    articleSummaryBlock.appendChild(articleSummaryImage);
  }

  articleSummaryBlock.appendChild(articleSummaryText);
  articleSummaryBlock.appendChild(articleSummaryCloseBtn);

  return {
    articleSummaryBlock,
    articleSummaryCloseBtn
  };
};

const initialiseOverlay = async () => {
  const commentsCount = document.querySelectorAll('.comments-count');
  const readerComments = document.getElementById('reader-comments');
  const commentsHeader = document.querySelector('div.rc-header');
  const taboolaSlot = document.getElementById('taboola-below-article-thumbnail-slot');
  const rcContent = document.querySelector('div.rc-content');
  const newCommentAnchor = document.querySelector('a#newcomment');
  const commentMessageBox = document.querySelector('textarea#message');
  const addYourCommentBtnInComments = document.querySelector('a#addYourCommentButton');
  const commentCtaButtons = document.querySelectorAll('[data-comment-cta-button]');

  const parentContainer = document.querySelector('div.alpha') || document.querySelector('div.gamma');
  const addCommentBtnOutsideOverlay = document.querySelector('a.add-comment__button');

  const isFloatingShareBarLoaded = await floatingShareBarLoaded;

  if (isFloatingShareBarLoaded) {
    const {articleSummaryBlock, articleSummaryCloseBtn} = getArticleSummaryBlock();

    const overlay = document.createElement('div');
    const overlayContent = document.createElement('div');

    overlay.id = 'comments-overlay';
    overlay.style.width = Number(parentContainer.offsetWidth) + 5 + 'px';
    overlayContent.id = 'comments-overlay-content';
    overlayContent.style.maxWidth = Number(parentContainer.offsetWidth) + 5 + 'px';

    overlay.appendChild(overlayContent);

    const initialPositions = {
      readerCommentsNextSibling: readerComments.nextSibling,
      readerCommentsParent: readerComments.parentNode,
      taboolaSlotNextSibling: taboolaSlot.nextSibling
    };

    const initialStyles = {
      commentMessageBoxStyles: commentMessageBox && commentMessageBox.style,
      commentsHeaderStyles: commentsHeader && commentsHeader.style
    };

    const openOverlay = () => {
      const newReaderCommentsId = 'reader-comments-in-overlay';

      readerComments.setAttribute('id', newReaderCommentsId);
      taboolaSlot.style.padding = '0 16px';

      if (commentMessageBox) {
        commentMessageBox.style.width = '100%';
        commentMessageBox.style.boxSizing = 'border-box';
      }

      overlay.style.left = parentContainer.getBoundingClientRect().left + 'px';
      overlayContent.appendChild(readerComments);
      overlayContent.appendChild(taboolaSlot);

      if (commentsHeader) {
        const stickyCommentsHeader = document.createElement('div');

        stickyCommentsHeader.id = 'sticky-comments-header';

        commentsHeader.style.border = 'none';
        commentsHeader.style.background = 'rgba(255, 255, 255, 1)';
        articleSummaryBlock.style.position = 'relative';

        stickyCommentsHeader.appendChild(articleSummaryBlock);
        stickyCommentsHeader.appendChild(commentsHeader);

        overlayContent.style.paddingTop = '220px';

        if (newCommentAnchor) {
          newCommentAnchor.style.display = 'block';
          newCommentAnchor.style.scrollMarginTop = '175px';
        }

        overlayContent.insertBefore(stickyCommentsHeader, overlayContent.firstChild);
      } else {
        overlayContent.insertBefore(articleSummaryBlock, overlayContent.firstChild);
        overlayContent.style.paddingTop = '120px';
      }

      // notify ads for billboard
      window.postMessage(
        {
          data: {
            isMolFeReaderCommentsOverlayOpen: true
          },
          scope: 'mol-fe-reader-comments-overlay'
        },
        window.location.href
      );
      window.isMolFeReaderCommentsOverlayOpen = true;

      window.scrollBy(
        {
          behavior: 'smooth',
          top: 450
        }
      );

      overlay.style.transform = 'translateY(100%)';
      requestAnimationFrame(() => {
        overlay.style.transform = 'translateY(0)';
      });

      document.body.appendChild(overlay);
      document.documentElement.style.overflow = 'hidden';
    };

    const closeOverlay = () => {
      window.scrollBy(
        {
          behavior: 'smooth',
          top: -450
        }
      );

      overlay.style.transform = 'translateY(0)';
      requestAnimationFrame(() => {
        overlay.style.transform = 'translateY(100%)';
      });

      setTimeout(() => {
        initialPositions.readerCommentsParent.insertBefore(readerComments, initialPositions.readerCommentsNextSibling);
        initialPositions.readerCommentsParent.insertBefore(taboolaSlot, initialPositions.taboolaSlotNextSibling);
        readerComments.style.padding = '0';
        readerComments.style.display = 'block';
        taboolaSlot.style.padding = '0';

        if (newCommentAnchor) {
          newCommentAnchor.style.scrollMarginTop = '0';
        }

        if (commentsHeader) {
          commentsHeader.style = initialStyles.commentsHeaderStyles;

          if (rcContent) {
            rcContent.insertBefore(commentsHeader, rcContent.firstChild);
          }
        }

        if (commentMessageBox) {
          commentMessageBox.style = initialStyles.commentMessageBoxStyles;
        }

        readerComments.setAttribute('id', 'reader-comments');
        document.body.removeChild(overlay);

        // notify ads for billboard
        window.postMessage(
          {
            data: {
              isMolFeReaderCommentsOverlayOpen: false
            },
            scope: 'mol-fe-reader-comments-overlay'
          },
          window.location.href
        );
        window.isMolFeReaderCommentsOverlayOpen = false;
      }, 500);
      document.documentElement.style.overflow = 'auto';
    };

    articleSummaryCloseBtn.addEventListener('click', () => {
      closeOverlay();
    });

    [...commentsCount, ...commentCtaButtons].forEach((btnEl) => {
      btnEl.addEventListener('click', (event) => {
        event.preventDefault();
        openOverlay();
      });
    });

    if (addCommentBtnOutsideOverlay) {
      addCommentBtnOutsideOverlay.addEventListener('click', (event) => {
        event.preventDefault();
        openOverlay();
        window.location.hash = '#newcomment';
      });
    }

    if (addYourCommentBtnInComments) {
      addYourCommentBtnInComments.addEventListener('click', () => {
        const showMoreBtn = document.querySelector('button.rc-show-more');

        if (showMoreBtn && readerComments.dataset.showMore === 'collapsed') {
          showMoreBtn.click();
        }
      });
    }

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        closeOverlay();
      }
    });

    window.addEventListener('resize', () => {
      overlay.style.left = parentContainer.getBoundingClientRect().left + 'px';
    });

    document.addEventListener('floating_unit_clicked', (event) => {
      event.preventDefault();
      openOverlay();
    });
  } else {
    logger.error('Error initialising reader comments overlay in mol-fe-reader-comments-overlay');
  }
};

const {ccRender: isCCrender} = getPageCriteria();
const loaded = (async () => {
  try {
    if (isCCrender) {
      return null;
    }

    await later('bundle');
    await later('DOM_READY');

    return initialiseOverlay();
  } catch (error) {
    logger.error(
      'Error during mol-fe-reader-comments-overlay initialisation: ',
      error
    );

    return null;
  }
})();

export {loaded};

// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';
import Hammer from 'hammerjs';
import {isIDevice} from '@mol-fe/mol-fe-mobileutils';
import {
  ONCLASS,
  trigger
} from './utilities';

const pinchzoom = (element, options) => {
  const opts = Object.assign({}, {disabled: false}, options);
  const touchitem = new Hammer(element);
  let center;
  let action;

  const getElementOffset = (el) => {
    const box = el.getBoundingClientRect();

    return {
      left: box.left + window.pageXOffset - document.documentElement.clientLeft,
      top: box.top + window.pageYOffset - document.documentElement.clientTop
    };
  };

  const getOffset = (event) => {
    const docScrollTop = document.documentElement && document.documentElement.scrollTop;
    const docScrollLeft = document.documentElement && document.documentElement.scrollLeft;
    const st = typeof docScrollTop === 'number' ? docScrollTop : document.body.scrollTop;
    const sl = typeof docScrollLeft === 'number' ? docScrollLeft : document.body.scrollLeft;
    const parentOffset = getElementOffset(element);
    const centerX = event.gesture.center.pageX;
    const centerY = event.gesture.center.pageY;
    const altCenterX = event.gesture.center.clientX;
    const altCenterY = event.gesture.center.clientY;

    const relX = centerX + sl - parentOffset.left;
    let relY = centerY + st - parentOffset.top;

    if (centerX === altCenterX && centerY > altCenterY && !isIDevice()) {
      relY = altCenterY + st - parentOffset.top;
    }

    return [relX, relY];
  };

  const handlers = {
    disable () {
      element.classList.remove(ONCLASS);
      touchitem.off('dragend');
      touchitem.off('doubletap');
      touchitem.off('pinch');
      touchitem.off('drag');
    },
    enable () {
      element.classList.add(ONCLASS);
      touchitem.on('dragend', handlers.end);
      touchitem.on('doubletap', handlers.reset);
      touchitem.on('pinch', handlers.zoom);
      touchitem.on('drag', handlers.pan);
    },
    end () {
      action = undefined;
      center = undefined;
      trigger(element, 'zoomable-end');
    },
    pan (event) {
      if (action === 'pinch') {
        return;
      }
      action = 'drag';

      const currentOffset = getOffset(event);

      if (!center) {
        center = getOffset(event);
      }
      trigger(element, 'zoomable-pan', {
        detail: [
          currentOffset[0] - center[0],
          currentOffset[1] - center[1]
        ]
      });

      event.stopPropagation();
      event.gesture.stopPropagation();
    },
    reset () {
      trigger(element, 'zoomable-reset');
    },
    zoom (event) {
      if (action === 'drag') {
        return;
      }

      action = 'pinch';
      center = getOffset(event);
      trigger(element, 'zoomable-zoom', {
        detail: {
          center,
          newscale: event.gesture.scale
        }
      });
    }
  };

  element.addEventListener('pinchzoom-reset', handlers.reset);
  element.addEventListener('pinchzoom-enable', handlers.enable);
  element.addEventListener('pinchzoom-disable', handlers.disable);

  if (!opts.disabled) {
    handlers.enable();
  }
};

export default pinchzoom;

const animate = (from, to, newSpeed, element, slides, index, options, delayCall) => {
  // if not an animation, just reposition
  if (!newSpeed) {
    element.style.left = to + 'px';

    return;
  }

  const startTime = Date.now();
  const timer = setInterval(() => {
    const timeElap = Date.now() - startTime;

    if (timeElap > newSpeed) {
      element.style.left = to + 'px';

      delayCall();

      if (options.transitionEnd) {
        options.transitionEnd.call(event, index, slides[index]);
      }

      clearInterval(timer);

      return;
    }

    const newLeft = (to - from) * (Math.floor(timeElap / newSpeed * 100) / 100) + from;

    element.style.left = newLeft + 'px';
  }, 4);
};

export default animate;

export const mountAd = (slotId, position, variant) => {
  if (!window.adverts) {
    window.adverts = {queue: []};
  } else if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    data: {
      position,
      slotId,
      variant
    },
    scope: 'adSlotMount'
  });
};

export const unmountAd = (slotId, position, variant) => {
  if (!window.adverts) {
    window.adverts = {queue: []};
  } else if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    data: {
      position,
      slotId,
      variant
    },
    scope: 'adSlotUnmount'
  });
};

export const updateAd = (slotId, position, variant) => {
  if (!window.adverts) {
    window.adverts = {queue: []};
  } else if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    data: {
      position,
      slotId,
      variant
    },
    scope: 'adSlotUpdate'
  });
};

export const notifyAdsGalleryClose = () => {
  if (!window.adverts) {
    window.adverts = {queue: []};
  } else if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    scope: 'imageGalleryClose'
  });
};

export const notifyAdsGalleryOpen = () => {
  if (!window.adverts) {
    window.adverts = {queue: []};
  } else if (!window.adverts.queue) {
    window.adverts.queue = [];
  }

  window.adverts.queue.push({
    scope: 'imageGalleryOpen'
  });
};

export const getSlotEnabled = (slotId) => Boolean(
  window.adverts &&
  window.adverts.config &&
  window.adverts.config.slots &&
  window.adverts.config.slots.enabled &&
  window.adverts.config.slots.enabled[slotId]
);

export const isAdFreeEntitled = () => {
  if ('isAdFreeEntitled' in window) {
    return window.isAdFreeEntitled();
  }

  return false;
};

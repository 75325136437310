class EventsManager {
  constructor () {
    this.eventRemovers = [];
  }

  add (element, eventName, handler) {
    element.addEventListener(eventName, handler);
    this.eventRemovers.push(() => element.removeEventListener(eventName, handler));
  }

  addComplex (elements, eventNames, handler) {
    Array.from(elements)
      .filter((element) => element.addEventListener)
      .forEach((element) =>
        eventNames.forEach((eventName) =>
          this.add(element, eventName, handler)));
  }

  removeAll () {
    let removeEvent;

    // eslint-disable-next-line no-cond-assign
    while (removeEvent = this.eventRemovers.pop()) {
      removeEvent();
    }
  }
}

export default EventsManager;

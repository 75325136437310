import React from 'react';
import classnames from 'classnames';
import {shareToEmail, shareToFacebook, shareToPinterest, shareToTwitter} from '../../api/social';
import {MoreGalleries} from '../MoreGalleries';
import styles from './styles.scss';

export const ShareSlide = ({isActive, mobileMode, onGetNextGallery, onGoToNextGallery, articleTitle}) =>
  <div className={classnames(styles.slide, mobileMode && styles.mobileMode)}>
    <div className={styles.contentWrapper}>
      {!mobileMode &&
        <div className={styles.galleryInfo}>
          <div className={styles.shareBar}>
            <div className={styles.shareBarLabel}>Share this gallery</div>
            <div className={styles.shareButtons}>
              <button aria-label='Share on Facebook' className={styles.facebookButton} onClick={shareToFacebook} />
              <button aria-label='Share on Twitter' className={styles.twitterButton} onClick={shareToTwitter} />
              <button aria-label='Share on Pinterest' className={styles.pinterestButton} onClick={shareToPinterest} />
              <button aria-label='Share on Email' className={styles.emailButton} onClick={() => shareToEmail()} />
            </div>
          </div>
          <div className={styles.articleTitle}>{articleTitle}</div>
        </div>
      }
      <MoreGalleries
        isActive={isActive}
        mode={mobileMode ? 'smallHorizontal' : 'horizontal'}
        onGetNextGallery={onGetNextGallery}
        onGoToNextGallery={onGoToNextGallery}
      />
    </div>
  </div>;

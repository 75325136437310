import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {createScriptElement, getArticleTags, getCanonicalUrl} from '@mol-fe/mol-fe-openweb-core';

export const getArticleScript = (articleId) => {
  const {openwebMessagesCount, readerComments} = getPageCriteria();

  const script = createScriptElement();

  script.dataset.postUrl = getCanonicalUrl();
  script.dataset.postId = articleId;

  if (openwebMessagesCount) {
    script.dataset.messagesCount = openwebMessagesCount;
  }

  if (readerComments === 'R') {
    script.dataset.readOnly = true;
  }

  script.dataset.articleTags = getArticleTags();

  return script;
};

import addClickHandlers from './addClickHandlers';
import {hideWhileCommentsVisible} from './hideWhileCommentsVisible';

export const handleFloatingBar = () => {
  const floatingBar = document.getElementById('floating-share-bar-container');
  const articleIconLinksContainer = document.getElementById(
    'articleIconLinksContainer'
  );

  addClickHandlers(floatingBar);
  hideWhileCommentsVisible(floatingBar);
  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];

    if (!entry.isIntersecting && entry.boundingClientRect.y < 0) {
      floatingBar.classList.add('sticky');
    } else {
      floatingBar.classList.remove('sticky');
    }
  });

  observer.observe(articleIconLinksContainer);
};

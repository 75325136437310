import {later} from '@mol-fe/mol-fe-async';

const THREE_COLUMN_CLASS = 'vjs-span-three-col';
const TWO_COLUMN_CLASS = 'vjs-span-two-col';

export const cloneNewVideo = (videoElement) => {
  const wrapper = document.createElement('div');

  wrapper.className = videoElement.className;
  wrapper.id = videoElement.id + '-clone';

  if (wrapper.classList.contains(TWO_COLUMN_CLASS)) {
    wrapper.classList.remove(TWO_COLUMN_CLASS);
    wrapper.classList.add(THREE_COLUMN_CLASS);
  }

  later('videoplayer-ready', () => {
    const video = videoElement.children[0];

    if (video.player) {
      const videoClone = video && window.DM.setupMolVideoJsPlayers.clone(
        video,
        {
          plugins: {
            tracking: {
              trackingType: 'image_gallery'
            }
          }
        }
      );

      if (!videoClone) {
        return;
      }

      videoClone.id = video.id + '-clone';
      wrapper.appendChild(videoClone);
    } else {
      const videoClone = video.cloneNode();

      let parsedOpts = JSON.parse(videoClone.dataset.opts);

      parsedOpts = {
        ...parsedOpts,
        plugins: {
          ...parsedOpts.plugins || {},
          tracking: {
            ...parsedOpts.plugins.tracking || {},
            trackingType: 'image_gallery'
          }
        }
      };

      videoClone.dataset.opts = JSON.stringify(parsedOpts);
      wrapper.appendChild(videoClone);
    }

    window.DM.setupMolVideoJsPlayers(wrapper);
  });

  return wrapper;
};

export const pauseAll = () => {
  try {
    window.DM.molFeVideoplayer.trigger('pauseAll', {});
  } catch (error) {
    // Ignore error
  }
};

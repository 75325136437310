import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {getRealRenderHeight} from './helpers';

const MIN_IMAGE_WIDTH_DESKTOP = 300;
const MIN_IMAGE_HEIGHT_DESKTOP = 300;
const MIN_IMAGE_WIDTH_MOBILE = 200;
const MIN_IMAGE_HEIGHT_MOBILE = 150;
const IMAGE_SELECTOR = 'img[id^=i-]';
const VIDEO_SELECTOR = 'div[id^=v-]';
const EXCLUDE_CONTAINERS_SELECTOR = '.mol-product';

const byImageSize = (isMobile, element) => {
  if (element.tagName !== 'IMG') {
    return true;
  }

  if (element.closest(EXCLUDE_CONTAINERS_SELECTOR)) {
    return false;
  }

  const width = element.width || parseInt(element.getAttribute('width'), 10);
  const height = getRealRenderHeight(element);
  const minHeight = isMobile ? MIN_IMAGE_HEIGHT_MOBILE : MIN_IMAGE_HEIGHT_DESKTOP;
  const minWidth = isMobile ? MIN_IMAGE_WIDTH_MOBILE : MIN_IMAGE_WIDTH_DESKTOP;

  return (
    height > minHeight + 70 ||
    height < minHeight + 70 && height >= minHeight && width > minWidth + 90
  );
};

let memoised = {};

export const clearGalleryItemsCache = () => {
  memoised = {};
};

export const getGalleryItems = ({allowVideos = true, dom = null, articleId = null} = {}) => {
  const memoKey = JSON.stringify({
    allowVideos,
    articleId
  });

  if (memoised[memoKey]) {
    return memoised[memoKey];
  }

  const {renderPlatform} = getPageMetadata();
  const isMobile = renderPlatform === 'mobile';
  const itemSelectors = [IMAGE_SELECTOR];

  if (allowVideos) {
    itemSelectors.push(VIDEO_SELECTOR);
  }

  const filterByImageSize = byImageSize.bind(null, isMobile);
  let index = 0;

  const galleryItems = Array.from(
    (dom || document).querySelectorAll(itemSelectors.join(','))
  )
    .filter(filterByImageSize)
    .map((element) => {
      const type = element.tagName === 'IMG' ? 'image' : 'videoimage';
      const item = {
        element,
        index,
        isNext: Boolean(dom),
        originalIndex: index,
        type
      };

      index++;

      return item;
    });

  memoised[memoKey] = galleryItems;

  return galleryItems;
};

import axios from 'axios';

const memoised = {};

export const getArticleDom = async (articleUrl) => {
  if (memoised[articleUrl]) {
    return memoised[articleUrl];
  }

  const response = await axios.get(articleUrl);

  const dom = new DOMParser().parseFromString(response.data, 'text/html');

  const noscripts = Array.from(dom.querySelectorAll('noscript'));

  for (const noscript of noscripts) {
    noscript.remove();
  }

  memoised[articleUrl] = dom;

  return dom;
};

const swapSlides = (first, second) => {
  const firstIndex = first.getAttribute('data-index');
  const firstLeft = first.style.left;

  second.after(first);
  first.setAttribute('data-index', second.getAttribute('data-index'));
  second.setAttribute('data-index', firstIndex);
  first.style.left = second.style.left;
  second.style.left = firstLeft;
};

const resetPositions = (gallery) => {
  const slides = gallery.querySelector('.slide-container');
  const width = slides.getBoundingClientRect().width;
  const parent = slides.parentNode;

  Array.from(parent.children).forEach((slide, index) => {
    const newLeft = width * -index;

    slide.setAttribute('data-index', index);
    slide.style.left = `${newLeft}px`;
  });
};

const resize = (slide, height, width) => {
  if (slide.getAttribute('data-video-opts')) {
    return;
  }

  let scaleFactor;

  scaleFactor = Math.min(height / slide.offsetHeight, width / slide.offsetWidth);
  if (slide.classList.contains('.vjs-video-container') && height < width) {
    scaleFactor *= 0.78;
  }

  ['-webkit-transform', '-moz-transition', '-o-transform', 'transform'].forEach((cssProperty) => {
    slide.style[cssProperty] = 'scale(' + scaleFactor + ')';
  });
};

const center = (slide) => {
  const verticalCenter = -(slide.offsetHeight / 2) + 'px';
  const horizontalCenter = -(slide.offsetWidth / 2) + 'px';

  if (slide.classList.contains('vjs-video-container') && slide.classList.contains('vjs-flyout-placeholder')) {
    const realSlide = slide.parentNode.querySelector('.vjs-flyout-wrapper-fixed .vjs-video-container');

    realSlide.style.marginTop = verticalCenter;
    realSlide.style.marginLeft = horizontalCenter;
  } else {
    slide.style.marginTop = verticalCenter;
    slide.style.marginLeft = horizontalCenter;
  }
};

const removeContainer = (container, gallery) => {
  container.parentNode.removeChild(container);
  resetPositions(gallery);
};

export {
  swapSlides,
  resetPositions,
  resize,
  center,
  removeContainer
};
